import React, { useState } from "react";
import atoms from "../../atoms";
import PaymentSearch from "./PaymentSearchTab/index"
import PaymentVoucherComponent from './paymentVoucherPayment'
import { numberWithCommas } from "../../../constants/utils";
import molecules from '../../molecules'


const { Input, Search, Button } = atoms
const { ConfirmationModal } = molecules

const Index = ({ searchCallBack, glAccountList, handleSearchSelect, paymentExtraDetail, handleExtraDetailChange, handleSubmitButton, searchItem, paymentsDetail, setPaymentsDetail }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [handlePaymentVoucher, setHandlePaymentVoucher] = useState<boolean>(false)

    const addComponent = () => {
        setPaymentsDetail([...paymentsDetail, {
            mode_of_payment: "",
            amount: 0,
            transaction_number: ""
        }])
    }

    const handleSubmitPaymentVoucher = () => {
        handleSubmitButton();
    }
    const handleDeleteComponent = (index: number) => {
        let tempComponent = [...paymentsDetail]
        tempComponent?.length > 1 ? tempComponent.splice(index, 1) : tempComponent = [{ mode_of_payment: "", amount: 0, transaction_number: "" }],
            setPaymentsDetail(tempComponent)
    }

    const handleChange = (index: number, name: string, value: number | { label: string, value: string }) => {
        const updatedComponents: any = [...paymentsDetail];
        updatedComponents[index][name] = value;
        setPaymentsDetail(updatedComponents);
    }

    // validation for payment voucher
    const isValidEntry = (entry: any) => {
        // Check if all fields are empty
        if(entry?.mode_of_payment?.value === 2){
            return entry.mode_of_payment !== '' && (entry.transaction_number !== null && entry.transaction_number !== '');
        }else return true
    }

    const validation = () => {
        if (paymentExtraDetail?.narration === '' || paymentExtraDetail?.remarks === '') {
            return false
        }

        // validation for SpecxWeb book
        const validSpecxWebDebit = paymentsDetail?.every(isValidEntry);
        const specxWebDebitAmount = paymentsDetail?.reduce((sum: number, item: any) => sum + (item?.amount || 0), 0);

        if (!validSpecxWebDebit || !(Number(specxWebDebitAmount) < Number(-searchItem?.balance))) {
            return false
        }

        return true
    }

    const isValid = () => {
        let value = validation()
        if (value) {
            setHandlePaymentVoucher(!handlePaymentVoucher);
        } else {
            handleSubmitPaymentVoucher();
        }
    }

    return (
        <>
            {/* <div> */}
            <div className='col-md-6 mt-3 position-relative' onClick={() => setIsOpen(!isOpen)}>
                <Search
                    placeholder="Search Mobile Number"
                    searchCallBack={(search: string) => searchCallBack(search)}
                    className='fs-16 new-custom-search' heading={'Search Product'} />
                {(glAccountList && isOpen) && <PaymentSearch data={glAccountList} searchCallBack={(item: any) => handleSearchSelect(item)} />}
            </div>
            {/* </div> */}
            {searchItem && <>
                <div className="row mt-2">
                    <div className='col-md-4 d-flex gap-3 align-items-center mt-md-3 mt-1'>
                        <h2 className='fs-16 fw-700 dark-gray'>Customer Name :</h2>
                        <div className='d-flex'>
                            <h2 className='fs-16 fw-400 dark-gray'>{searchItem?.gl_account_name ? searchItem?.gl_account_name : ""}</h2>
                        </div>
                    </div>
                    <div className='col-md-4 d-flex gap-3 align-items-center mt-md-3 mt-1 voucher-padding-lg'>
                        <h2 className='fs-16 fw-700 dark-gray'>Mobile Number :</h2>
                        <div className='d-flex'>
                            <h2 className='fs-16 fw-400 dark-gray'>{searchItem?.gl_number ? searchItem?.gl_number : ""}</h2>
                        </div>
                    </div>
                    <div className='col-md-4 d-flex gap-3 align-items-center mt-md-3 mt-1 voucher-padding-lg'>
                        <h2 className='fs-16 fw-700 dark-gray'>Account Balance :</h2>
                        <div className='d-flex'>
                            <h2 className='fs-16 fw-400 dark-gray'>₹{searchItem?.balance ? numberWithCommas(parseFloat((-searchItem?.balance)?.toFixed(2))) : 0}</h2>
                        </div>
                    </div>
                </div>
                <div className="mt-md-4 mt-2">
                    {paymentsDetail?.map((component: any, index: number) => (
                        <div className="mt-2 payment-voucher-card">
                            <PaymentVoucherComponent
                                handleChange={(name: string, value: number | { label: string, value: string }) => handleChange(index, name, value)}
                                transaction_number={component?.transaction_number}
                                amount={component?.amount}
                                glAccountList={glAccountList}
                                mode_of_payment={component?.mode_of_payment}
                                Index={(paymentsDetail?.length === (index + 1)) && index + 1}
                                addComponent={() => addComponent()}
                                handleDeleteComponent={() => handleDeleteComponent(index)}
                            />
                        </div>
                    ))}
                </div>
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <Input
                            label={"Remark"}
                            className="fs-16"
                            value={paymentExtraDetail?.remarks}
                            placeholder={"Enter Remark"}
                            type="Text"
                            onChange={(e: any) => handleExtraDetailChange("remarks", e.target.value)}
                        />
                    </div>
                    <div className="col-md-4 mt-3">
                        <Input
                            label={"Narration"}
                            className="fs-16"
                            value={paymentExtraDetail?.narration}
                            placeholder={"Enter Narration"}
                            type="Text"
                            onChange={(e: any) => handleExtraDetailChange("narration", e.target.value)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4" ><Button style={{ minWidth: "20vw" }} onClick={() => isValid()}>Submit</Button></div>
            </>}
            {
                handlePaymentVoucher && <ConfirmationModal
                    show={handlePaymentVoucher}
                    onHideButtonText={"Cancel"}
                    confirmButtonText={"OK"}
                    handleConfirmItem={() => { handleSubmitPaymentVoucher(); setHandlePaymentVoucher(!handlePaymentVoucher) }}
                    onHide={() => setHandlePaymentVoucher(!handlePaymentVoucher)}
                    message={'create payment voucher'} />
            }
        </>
    )
}

export default Index