import React, { useEffect, useState } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import molecules from "../../../../components/molecules";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { useToasts } from "react-toast-notifications";
import { generatePdf, numberWithCommas } from "../../../../constants/utils";
import { useNavigate } from "react-router";
import constant from "../../../../constants/constant";
const { TopBar, PopUpModal } = molecules;
const { EAccountDetail } = organisms;

const Index = () => {
   let filterPath = localStorage.getItem("product_filter") || constant.PRODUCT_FILTER_TYPE.DEFAULT;
  const [invoiceResponse, setInvoiceResponse] = useState({
    customer_name: "",
    id: "",
    order_id: "",
    payment_method: "",
    total_payment: NaN,
  });
  const [accountData, setAccountData] = useState({
    name: "",
    mobile: "",
    gstNumber: "",
    address: "",
    cardAmount: "",
    cardDetail: "",
    upiAmount: "",
    upiDetails: "",
    cashAmount: "",
    accountBalance: "",
    totalAmount: "",
  });
  const [accountDetails, setAccountDetails] = useState({
    name: "",
    mobile: "",
    totalPayable: "",
    loyalty_points: 0,
    accountBalance: "",
    redemption_request: true,
  });
  const [redirection, setRedirection] = useState("");
  const [showModal, setShowModal] = useState(false);
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const param = searchParams.get("s");
  const orderId: string = searchParams.get("orderID") || "";
  const createAdvanceReceipt = async () => {
    const payload = {
      alternate_name: accountData.name,
      alternate_mobile: accountData.mobile,
      gst_number: accountData.gstNumber,
      delivery_address: accountData.address,
      card_amount: parseFloat(accountData.cardAmount),
      card_details: accountData.cardDetail,
      upi_amount: parseFloat(accountData.upiAmount),
      upi_details: accountData.upiDetails,
      cash_amount: parseFloat(accountData.cashAmount),
      account_balance: parseFloat(accountData.accountBalance),
      totalAmount: parseFloat(accountData.cashAmount),
    };
    try {
      let response: any;
      // if (param == "1") {
      //   response = await service.SalesCart.advanceReceipt(payload);
      // } else if (param == "2") {
      // }
      response = await service.PendingOrder.advanceReceipt(orderId, payload);
      if (response.status === 200) {
        setInvoiceResponse(response.data.data);
        setShowModal(true);
        localStorage.setItem("sales_count", "0");
        localStorage.setItem("salesCartTimerPopup", "0");
        setRedirection("pending-receipt");
      }
    } catch (error: any) {
      addToast(error?.response?.data?.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      console.log("error", error);
    }
  };
  const createInvoice = async () => {
    const payload = {
      alternate_name: accountData.name,
      alternate_mobile: accountData.mobile,
      gst_number: accountData.gstNumber,
      delivery_address: accountData.address,
      card_amount: parseFloat(accountData.cardAmount),
      card_details: accountData.cardDetail,
      upi_amount: parseFloat(accountData.upiAmount),
      upi_details: accountData.upiDetails,
      cash_amount: parseFloat(accountData.cashAmount),
      account_balance: parseFloat(accountData.accountBalance),
      totalAmount: parseFloat(accountData.cashAmount),
    };
    try {
      let response: any;
      // if (param == "1") {
      //   response = await service.SalesCart.createInvoice(payload);
      // } else if (param == "2") {
      // }
      response = await service.PendingOrder.createInvoice(orderId, payload);
      if (response.status === 200) {
        setInvoiceResponse(response.data.data);
        setShowModal(true);
        localStorage.setItem("sales_count", "0");
        localStorage.setItem("salesCartTimerPopup", "0");
        setRedirection("delivered");
      }
    } catch (error: any) {
      addToast(error?.response?.data?.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      console.log("error", error);
    }
  };

  const calculateTotalAmount = (data: typeof accountData) => {
    const cardAmount = parseFloat(data.cardAmount) || 0;
    const upiAmount = parseFloat(data.upiAmount) || 0;
    const cashAmount = parseFloat(data.cashAmount) || 0;
    const accountBalance = parseFloat(data.accountBalance) || 0;
    const finalAmount = (
      cardAmount +
      upiAmount +
      cashAmount +
      accountBalance
    ).toString();
    return finalAmount;
  };

  const handleGeneratePdf = async () => {
    // generatePdf(invoiceResponse?.id)
    const response = await service.SalesCart.sharePdf(invoiceResponse?.id, {
      share: true,
    });
    if (response.status === 200) {
      addToast(response?.data?.message, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
    setShowModal(false);
    setTimeout(() => {
      redirection === "pending-receipt"
        ? navigate("/online/e-orders")
        : navigate("/online/sales-history");
    }, 1000);
  };
  const cancelCart = async () => {
    try {
      let response: any;
      if (param == "1") {
        response = await service.SalesCart.cancelCart();
      }
      if (response.status === 200) {
        navigate(`/product/list?${filterPath}`)
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    const totalAmount = calculateTotalAmount(accountData);
    if (totalAmount !== accountData.totalAmount) {
      setAccountData((prevData) => ({
        ...prevData,
        totalAmount: totalAmount,
      }));
    }
  }, [accountData]);

  useEffect(() => {
    let data = {
      name: "",
      mobile: "",
      totalPayable: "",
      loyalty_points: 0,
      accountBalance: "",
      redemption_request: true,
    };
    var retrievedDataString = sessionStorage?.getItem("e_account_details") || "";
    // var retrievedData = JSON?.parse(retrievedDataString || "");
    try {
    var retrievedData = JSON.parse(retrievedDataString);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
    }
    setAccountDetails(retrievedData || data);
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <div className="accounting-main">
        <div className="accounting-fixed">
          <div className="row align-items-center pt-3 mt-2">
            <TopBar heading="Cart" />
          </div>

          <div className="d-flex flex-wrap gap-0 gap-lg-5 gap-md-3 align-items-center pt-2">
            <div className="d-flex flex-wrap gap-3">
              <p className="mb-0 accountDetail-text-Size fw-500">
                Customer Name :{" "}
              </p>
              <p className="mb-0 accountDetail-text-Size fw-400">
                {accountDetails?.name}
              </p>
            </div>

            <div className="d-flex flex-wrap gap-3">
              <p className="mb-0 accountDetail-text-Size fw-500 ">
                Mobile Number :
              </p>
              <p className="mb-0 accountDetail-text-Size fw-400">
                {accountDetails?.mobile}
              </p>
            </div>

            <div className="d-flex flex-wrap gap-3">
              <p className="mb-0 accountDetail-text-Size fw-500 ">
                Payable Amount :
              </p>
              <p className="mb-0 accountDetail-text-Size fw-400">
                ₹
                {numberWithCommas(
                  parseFloat(
                    Number(
                      accountDetails?.loyalty_points > 0
                        ? parseFloat(accountDetails?.totalPayable) +
                        (accountDetails?.redemption_request
                          ? 0
                          : accountDetails?.loyalty_points)
                        : accountDetails?.totalPayable
                    )?.toFixed(2)
                  )
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            marginTop: `${window?.innerWidth < 768 ? "11rem" : "5rem"}`,
          }}
        >
          <EAccountDetail
            accountData={accountData}
            setAccountData={setAccountData}
            createAdvanceReceipt={createAdvanceReceipt}
            createInvoice={createInvoice}
            setShowModal={setShowModal}
            showModal={showModal}
            invoiceResponse={invoiceResponse}
            generatePdf={handleGeneratePdf}
            cancelCart={cancelCart}
            accountDetails={accountDetails}
            setAccountDetails={setAccountDetails}
          />
        </div>
      </div>

      {showModal && (
        <div>
          <PopUpModal
            show={showModal}
            onHide={() => {
              setShowModal(false);
              navigate(`/product/list?${filterPath}`)
            }}
            invoiceResponse={invoiceResponse}
            handleGeneratePdf={handleGeneratePdf}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default Index;
