import atoms from "../../atoms";
import { numberWithCommas } from "../../../constants/utils";
import { useEffect, useRef } from "react";

const { Pagination, Search, Table, CustomImages, ToggleContainer } = atoms

const Index = ({ productList, setSearch, currentPage, setCurrentPage, setLimitPerPage, limit, isContactLensProduct, setIsContactLensProduct }: any) => {
    const searchRef: any = useRef()
    const columns = [
        { name: 'Product ID', selector: (row: any) => row?.product?.product_id_display, },
        { name: 'Product Type', selector: (row: any) => row?.product?.product_type?.name, },
        { name: 'Cover Image', cell: (row: any) => <CustomImages src={row?.product?.images[0] && row?.product?.images[0]} height="75%" />, center: true },
        { name: 'With Case', selector: (row: any) => row?.product?.product_with_case ? "Yes" : "No", },

        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name || '-', },
        { name: 'Brand Color Code', selector: (row: any) => row?.product?.brand_color_code || '-', },
        { name: 'Model No', selector: (row: any) => row?.product?.modal_number || '-', },
        { name: 'Glass Size/Power', selector: (row: any) => row?.product?.glass_size || '-', },

        { name: 'SRP', selector: (row: any) => `₹${numberWithCommas(parseFloat(row?.product?.product_srp?.toFixed(2)))}` || '-', },
    ]
    const columnsTwo = [
        { name: 'Product ID', selector: (row: any) => row?.product?.product_id_display, },
        { name: 'Product Type', selector: (row: any) => row?.product?.product_type?.name, },
        { name: 'Cover Image', cell: (row: any) => <CustomImages src={row?.product?.images[0] && row?.product?.images[0]} height="75%" />, center: true },
        { name: 'Brand', selector: (row: any) => row?.product?.brand?.brand_name || '-', },
        { name: 'Color', selector: (row: any) => row?.product?.color?.color_name || '-', },
        { name: 'SRP', selector: (row: any) => `₹${numberWithCommas(parseFloat(row?.product?.product_srp?.toFixed(2)))}` || '-', },
    ]

    useEffect(() => {
        if (searchRef?.current) {
            searchRef.current.value = "";
        }
    }, [isContactLensProduct]);

    const handleToggle = (value: any) => {
        setSearch('')
        setIsContactLensProduct(value)
    }

    return (
        <>
            <div className='row mt-3'>
                <div className='col-12 col-md-6'>
                    <Search className='search search-style' inputRef={searchRef} searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                <div className='col-12 col-md-6 d-flex justify-content-end'>
                    <ToggleContainer firstName="Contact Lens" secondName="Other Products" value={isContactLensProduct}
                        onStatusChange={(value: boolean) => { handleToggle(value) }} />
                </div>
                <div>
                    <Table
                        columns={isContactLensProduct ? columnsTwo : columns}
                        data={productList?.data?.map((item: any) => {
                            return ({
                                ...item
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        totalCount={productList?.count}
                        currentPage={currentPage}
                        totalPages={productList?.totalPages}
                        onChangePage={(page: any) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    )
}

export default Index