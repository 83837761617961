import React, { useState } from 'react'
import atoms from '../../atoms'
import { MdOutlineFileDownload } from "react-icons/md"
import Images from '../../../assets/Images'
import { numberWithCommas } from '../../../constants/utils'
import moment from 'moment';
import { FiShare } from "react-icons/fi";
import ThreeDots from '../../../assets/Images/threeDots.svg'
import { salesReturnHistoryProps, salesReturnHistoryList } from './interface'
const { CustomSearch, Table, Pagination, CustomImages, Image } = atoms
const { Coin, Currency } = Images

const Index = ({ salesReturnHistoryList, salesReturnListApi, currentPage, setCurrentPage, setLimitPerPage, limitPerPage, downloadCreditNote }: salesReturnHistoryProps) => {
    const [isOpen, setIsOpen] = useState(false);


    const columns = [
        { name: 'Date', selector: (row: salesReturnHistoryList) => moment(row?.createdAt).format('DD/MM/YYYY'), width: '100px' },
        { name: 'Customer Name', selector: (row: salesReturnHistoryList) => row?.customer?.name, width: '150px' },
        { name: 'Contact Number', selector: (row: salesReturnHistoryList) => row?.customer?.mobile || '-', width: '150px' },
        { name: 'Invoice Number', selector: (row: salesReturnHistoryList) => row?.order?.invoice_number || '-', width: '200px' },
        { name: 'Product Id', selector: (row: salesReturnHistoryList) => row?.product?.product_id_display || '-', width: '200px' },
        { name: 'Product Image', cell: (row: salesReturnHistoryList) => <CustomImages src={row?.product?.images[0]} height="75%" />, center: true, width: '150px' },
        { name: 'SRP', selector: (row: salesReturnHistoryList) => numberWithCommas(parseFloat((row?.product?.product_srp || 0)?.toFixed(2))), width: '150px', },
        { name: 'Amount Paid', selector: (row: salesReturnHistoryList) => numberWithCommas(parseFloat(row?.paid_amount?.toFixed(2))) || '-', width: '120px' },
        { name: 'Credit Note Number', selector: (row: salesReturnHistoryList) => row?.credit_note_number || '-', width: '120px' },

        { name: 'Action', selector: (row: any) => row?.action },


    ];

    return (
        <div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className=''>
                        <CustomSearch
                            setIsOpen={setIsOpen}
                            searchCallBack={(search: string) => salesReturnListApi(search)}
                            placeholder='Search Product ID'
                            className='fs-16'
                            heading="Search"
                            buttonStyle="w-25" />
                    </div>
                </div>
            </div>
            <Table
                columns={columns}
                data={salesReturnHistoryList?.data?.map((item: salesReturnHistoryList) => {
                    return ({
                        ...item,
                        action: <>
                            {<div className='table-data-width'>
                                <button
                                    className="btn dropdown-dots shadow-none action-button"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <Image src={ThreeDots} />
                                </button>
                                <ul
                                    className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                    aria-labelledby="dropdownMenuButton1"
                                    style={{ width: "150px" }}
                                >
                                    {/* Download Option */}

                                    <li>
                                        <button className="dropdown-item dropdown-item-size"
                                            onClick={() => downloadCreditNote(item?.id)}>
                                            <div className='d-flex align-items-center'>
                                                <MdOutlineFileDownload />
                                                <span className='ms-2 mb-0'>Download</span>
                                            </div>
                                        </button>
                                    </li>
                                    <hr className="my-1"></hr>

                                    {/* Share Option */}
                                    <li>
                                        <button className="dropdown-item dropdown-item-size"
                                            onClick={() => downloadCreditNote(item?.id, true)}>
                                            <div className='d-flex align-items-center'>
                                                <FiShare />
                                                <span className='ms-2 mb-0'>Share</span>
                                            </div>
                                        </button>
                                    </li>


                                </ul>
                            </div>}
                        </>
                        // action: <>
                        //     <div className="custom-download-icon-style" onClick={()=>downloadCreditNote(item?.id)}>
                        //         <MdOutlineFileDownload />
                        //     </div>
                        // </>
                    })
                })}
            />
            <div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={salesReturnHistoryList?.totalPages}
                    onChangePage={(page: number) => setCurrentPage(page)}
                    setLimit={(e: number) => setLimitPerPage(e)}
                    limit={limitPerPage}
                />
            </div>
        </div>
    )
}

export default Index