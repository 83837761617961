import './footer.scss'
import { useNavigate } from "react-router-dom";

import { BiCheckShield } from 'react-icons/bi';
import { IoListCircleOutline } from "react-icons/io5";
import { MdOutlineInventory } from "react-icons/md";
import { BsBoxArrowInDownLeft } from "react-icons/bs";
import { LuCalendarClock } from "react-icons/lu";
import constant from '../../../constants/constant';

const Index = () => {
  let filterPath = localStorage.getItem("product_filter") || constant.PRODUCT_FILTER_TYPE.DEFAULT;

  const navigate = useNavigate();

  return (
    <>
      <div className="d-md-none footer-container">
        <div className="footer-box">
          <div className="d-flex justify-content-between">
            <span onClick={() => navigate("/inventory-audit")}><MdOutlineInventory className="fs-24" /></span>
            <span onClick={() => navigate("/warranty")}><BiCheckShield className="fs-24" /></span>
            <span onClick={() => navigate(`/product/list?${filterPath}`)} ><IoListCircleOutline className="fs-24" /></span>
            <span onClick={() => navigate("/pending-receipt")}><LuCalendarClock className="fs-24" /></span>
            <span onClick={() => navigate("/transfer-request")}><BsBoxArrowInDownLeft className="fs-24" /></span>
          </div>
        </div>
      </div>
      <div className='d-md-none footer-empty-div'></div>
    </>
  )
}

export default Index