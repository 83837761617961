import React, { useEffect, useState, useContext } from "react";
import { ShowActiveTab } from "../../../Context";
import Wrapper from "../../../components/common/Wrapper/Wrapper";
import molecules from "../../../components/molecules";
import organisms from "../../../components/organisms";
import service from "../../../api/services";
import { IBillingSummary, ICustomerInformation } from "./interface";
import constant from "../../../constants/constant";
import { calculateDeliveryDate } from "../../../constants/utils";

const { TopBar } = molecules;
const { SalesCart } = organisms;

const Index = () => {
  const { setActiveTab }: any = useContext(ShowActiveTab);
  const [cartData, setCartData] = useState<any>();
  const [customerInfo, setCustomerInfo] = useState<ICustomerInformation>({
    name: "",
    mobile: "",
    loyalty_points: 0,
    loyalty_points_limit: NaN,
    redemption_request: false,
  });
  const [searchProduct, setSearchProduct] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("barcode");
  const [productList, setProductList] = useState<string>("");
  const [billingSummary, setBillingSummary] = useState<IBillingSummary>({
    subTotal: NaN,
    roundOff: NaN,
    totalSales: NaN,
    totalDiscount: NaN,
    loyalty_points: NaN,
    loyalty_points_limit: NaN,
    totalSalesToShow: NaN,
    redemption_request: false,
    deliveryDate: null,
  });
  const [selectedCartItems, setSelectedCartItems] = useState([
    {
      cart_item_id: "",
      discount: "",
      product_srp: "",
      isDelivered: "",
      inventory_item_id: "",
    },
  ]);

  const getViewCart = async (redemption_request?: boolean) => {
    try {
      const response = await service.SalesCart.viewCart();
      if (response.status === 200) {
        let res = response.data.data;
        // localStorage.setItem("sales_count", res?.cart_items?.length)
        handlePreviousDiscount(res);

        if (res?.customer) {
          setCustomerInfo({
            name: res.customer.name,
            mobile: res.customer.mobile,
            loyalty_points: res.customer?.loyalty_points,
            loyalty_points_limit: response?.data?.minimum_redeemable_points,
            accountBalance: res?.customer?.account?.balance,
            redemption_request: false,
          });
        }
        let total = (res?.cart_items || []).reduce(
          (
            sum: number,
            item: {
              product: { product_srp: number };
              other_product_data: { price: number };
            }
          ) =>
            sum +
            (item?.product?.product_srp || item?.other_product_data?.price),
          0
        );
        setBillingSummary({
          ...billingSummary,
          subTotal: total,
          totalSales: total,
          roundOff: res?.round_off_amount,
          loyalty_points: Math.min(
            res?.customer?.loyalty_points,
            total -
            res?.round_off_amount -
            res?.cart_items?.reduce(
              (sum: number, item: any) => sum + (item?.discount || 0),
              0
            )
          ),
          deliveryDate: calculateDeliveryDate(response?.data?.data?.store?.estimated_delivery_days),
          loyalty_points_limit: response?.data?.data?.minimum_redeemable_points,
          totalSalesToShow: total,
          redemption_request:
            redemption_request === false
              ? redemption_request
              : res?.redemption_request,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handlePreviousDiscount = (cardDataCopy: any) => {
    const updatedArr1 =
      cardDataCopy &&
      cardDataCopy?.cart_items?.map((item1: any) => {
        const matchingItem2 = selectedCartItems?.find(
          (item2) => item1.id === item2.cart_item_id
        );
        if (matchingItem2) {
          // If there is a match, update the discount value
          return {
            ...item1,
            discount: matchingItem2.discount,
            isDelivered:
              item1.status == constant.ORDER_STATUS.DELIVERED ? true : false,
          };
        }
        // If there is no match, keep the original item
        return {
          ...item1,
          discount: item1.discount > 0 ? item1.discount : null,
          isDelivered:
            item1.status == constant.ORDER_STATUS.DELIVERED ? true : false,
        };
      });
    setCartData({
      ...cartData,
      cart_items: updatedArr1,
      searchType: searchType,
    });
  };
  useEffect(() => {
    setActiveTab("salesCart");
    getViewCart();
  }, []);

  const handleSearchCustomer = async (number: string) => {
    setCustomerInfo({
      name: "",
      mobile: number,
      isSearch: number.length >= 10 ? true : false,
    });
    let res;
    try {
      if (number.length >= 10) {
        const response = await service.SalesCart.search({ search: number });
        if (response.status === 200) {
          res = response.data.data[0];
          if (res) {
            setCustomerInfo({
              name: res.name,
              mobile: res.mobile,
              loyalty_points: res?.loyalty_points,
              accountBalance: res?.account?.balance,
              isSearch: true,
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _productSearch = async () => {
    try {
      const response = await service.Product.list({
        [searchType]: searchProduct,
      });
      if (response.status === 200) {
        setProductList(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();

    if (selectedDate >= currentDate) {
      setBillingSummary({
        ...billingSummary,
        deliveryDate: selectedDate.toISOString().split("T")[0]
      })
    }
  };

  useEffect(() => {
    searchProduct !== "" && _productSearch();
  }, [searchProduct]);

  useEffect(() => {
    setCartData({ ...cartData, searchType: searchType });
  }, [searchType]);

  return (
    <Wrapper>
      <div className="row align-items-center">
        <TopBar heading="Cart" />
      </div>
      <div>
        <SalesCart
          cart_data={cartData}
          handleSearchCustomer={handleSearchCustomer}
          getViewCart={getViewCart}
          customerInfo={customerInfo}
          setCustomerInfo={setCustomerInfo}
          setSearchProduct={setSearchProduct}
          productList={productList}
          setBillingSummary={setBillingSummary}
          billingSummary={billingSummary}
          selectedCartItems={selectedCartItems}
          setSelectedCartItems={setSelectedCartItems}
          setSearchType={setSearchType}
          handleChangeData={handleChangeData}
        />
      </div>
    </Wrapper>
  );
};

export default Index;
