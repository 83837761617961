import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { IoMdCopy } from 'react-icons/io';
import molecules from '../../../molecules';
import atoms from '../../../atoms';
import constant from '../../../../constants/constant';
import service from '../../../../api/services';
import { useSearchParams } from 'react-router-dom';
const { ECheckoutCard, ECheckoutSummary } = molecules
const { Button } = atoms
const Index = ({ orderDetails, getOrderDetails, setSearchProduct, productList, billingSummary,
    setBillingSummary, selectedCartItems, setSelectedCartItems, setSearchType, handleApproveOrder,handleChangeData }: any) => {
    const { addToast } = useToasts();
    const navigate = useNavigate()
    let params = useParams();
    const [searchParams] = useSearchParams();
    const isApproved = searchParams.get('isApprove') === 'true'
    const isCancelledOrder = searchParams.get('e-cancelled') === 'true'

    let orderID = params.id || ''

    const _handleSelectedCartItems = () => {
        const selectedItems = orderDetails && orderDetails?.order_items?.map((item: any) => (
            {
                order_item_id: item?.id,
                product_srp: parseFloat((item?.product?.product_srp) || item?.other_product_data?.price),
                discount: Number(item?.discount),
                inventory_item_id: item?.inventory_item_id,
                isDelivered: (item.status == constant.ORDER_ITEM_STATUS.DELIVERED || item.status == constant.ORDER_ITEM_STATUS.READY_TO_DELIVERED) ? true : false
            }
        ))
        setSelectedCartItems(selectedItems)
    }
    const _handleCheckout = async () => {
        const payload = {
            round_off_amount: billingSummary.roundOff,
            order_items: selectedCartItems,
            redeemed_points: billingSummary?.redemption_request ? billingSummary?.loyalty_points : 0,
            redemption_request: billingSummary?.redemption_request,
            delivery_date: new Date(billingSummary?.deliveryDate)
        }
        let totalPayable = (Number(billingSummary?.totalSales) - Number(billingSummary?.total_paid_amount) - Number(billingSummary?.coupon_discount_amount))?.toFixed(2) || 0
        var accDetails = { name: orderDetails?.customer?.name, mobile: orderDetails?.customer?.mobile, totalPayable: totalPayable, loyalty_points: (billingSummary?.redemption_request ? billingSummary?.loyalty_points : 0), redemption_request: billingSummary?.redemption_request }
        var accDetailsString = JSON.stringify(accDetails);

        sessionStorage.setItem('account_details', accDetailsString);

        // To add cart data to session storage
        var cartItem = JSON.stringify(selectedCartItems);
        sessionStorage.setItem('card_details', cartItem);

        if (!hasDuplicateInventoryItemId(selectedCartItems)) {
            try {
                const response = await service.PendingOrder.checkout(orderID, payload)
                if (response.status === 200) {
                    navigate(`/account-details?s=${constant.Account_Detail_Constant.PENDING_ORDER}&orderID=${orderID}`);
                }
            } catch (error: any) {
                addToast(error?.response?.data?.message, {
                    appearance: 'error',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                console.log('error', error)
            }
        }
        else {
            addToast('Same barcode cant be assigned to different products ', {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }
    const hasDuplicateInventoryItemId = (data: any) => {
        const inventoryItemIds = new Set();

        for (const item of data) {
            const inventoryItemId = item.inventory_item_id;
            // Check if inventory_item_id is already in the Set
            if (item.isDelivered == true && inventoryItemId !== null && inventoryItemId !== undefined && inventoryItemIds.has(inventoryItemId)) {
                return true; // Duplicate found
            }

            // Add inventory_item_id to the Set
            inventoryItemIds.add(inventoryItemId);
        }

        return false; // No duplicates found
    }
    const calculateTotalPayable = () => {
        // Ensure discount and round-off are not negative
        const validDiscount = Number(billingSummary?.totalDiscount) >= 0 ? Number(billingSummary?.totalDiscount ): 0;
        const validRoundOff = Number(billingSummary?.roundOff) ? Number(billingSummary?.roundOff) : 0;
        const loyaltyPoints = Number(billingSummary?.loyalty_points) >= 0 ? Number(billingSummary?.loyalty_points) : 0;

        // Calculate the total payable amount
        const totalPayable = Number(billingSummary?.subTotal) - Number(validDiscount) - validRoundOff - (billingSummary?.redemption_request && Number(loyaltyPoints));
        const totalSalesTo = Number(billingSummary?.subTotal) - validDiscount - validRoundOff

        // Make sure total payable is not negative
        const finalTotalPayable = totalPayable >= 0 ? totalPayable : 0;
        setBillingSummary({ ...billingSummary, totalSales: finalTotalPayable, totalSalesToShow: totalSalesTo })

    }
    const calculateTotalDiscount = () => {
        const totalDiscount = (selectedCartItems || []).reduce((total: number, obj: { discount: any }) => obj.discount >= 0 ? total + obj.discount : 0, 0);
        setBillingSummary({ ...billingSummary, totalDiscount: totalDiscount })
    }

    const cancelCart = async () => {
        try {
            const response = await service.PendingOrder.cancelOrder(orderID)
            if (response.status === 200) {
                navigate('/online/cancelled-history')
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        calculateTotalPayable()
    }, [billingSummary?.roundOff, billingSummary?.totalDiscount, billingSummary?.redemption_request])

    useEffect(() => {
        calculateTotalDiscount()

    }, [selectedCartItems])
    useEffect(() => {
        // calculateTotal()
        _handleSelectedCartItems()

    }, [orderDetails])
    return (
        <div className='position-relative' style={{ height: '100vh' }}>
            <div className="row position-relative" >
                <hr style={{ border: '1px dashed #22222260', }} />
                <div className="col-12">
                    <p className='fs-md-16 fs-14 fw-700'>Customer Details</p>
                </div>
            </div>
            <div className="d-md-flex justify-content-between ">
                <div className="">
                    <div className='d-flex'>
                        <div>
                            <p className='fs-md-16 fs-12 fw-500'>Customer Name : <span className='fw-400'>{orderDetails?.customer?.name}</span> </p>
                        </div>
                        <div className='ms-3'>
                            <p className='fs-md-16 fs-12 fw-500'>Mobile Number :  <span className='fw-400'>{orderDetails?.customer?.mobile}</span> </p>
                        </div>
                        {orderDetails?.gst_number && <div className='ms-3'>
                            <p className='fs-md-16 fs-12 fw-500'>GST Number : <span className='fw-400'>{orderDetails?.gst_number}</span> </p>
                        </div>}
                        {orderDetails?.alternate_mobile && <div className='ms-3'>
                            <p className='fs-md-16 fs-12 fw-500'>Alternate Mobile Number :  <span className='fw-400'>{orderDetails?.alternate_mobile}</span> </p>
                        </div>}
                    </div>
                    {orderDetails?.delivery_address && <div className='d-flex'>
                        <div className=''>
                            <p className='fs-md-16 fs-12 fw-500'>Address :  <span className='fw-400'>{orderDetails?.delivery_address}</span> </p>
                        </div>
                    </div>}
                </div>
                {orderDetails?.invoice_number && <div className="">
                    <p className='fs-md-16 fs-12 fw-600'>Invoice ID :-  <Button className="fs-14 fw-400 px-3 py-2 mx-2"
                        style={{ background: 'transparent', color: 'black', border: '1px solid #D9D9D9' }}>
                        <div className='d-flex align-items-center'>
                            <span className='ms-2 mb-0' style={{ color: '#505D6F' }}>{orderDetails?.invoice_number}</span>
                            <IoMdCopy className='fs-20 mx-3' style={{ color: '#d9d9d9' }} />
                        </div>
                    </Button>
                    </p>
                </div>}
                {/* Do not remove this */}
                {/* <div><p className='fs-16 fw-600'>Account Balance</p> <p className='fs-16 fw-400'>{orderDetails?.account_balance || '0'}</p></div> */}
                {/* <div><p className='fs-16 fw-600'>Loyalty Points</p><p className='fs-16 fw-400'>{orderDetails?.customer?.loyalty_points}</p></div> */}

            </div>

            <hr className="mt-1" style={{ border: '1px dashed #22222260', }} />

            <div className="row mt-3 ">
                <div className="col-12 col-md-8">
                    <ECheckoutCard
                        orderDetails={orderDetails}
                        getViewCart={getOrderDetails}
                        productList={productList}
                        setSearchProduct={setSearchProduct}
                        setSelectedCartItems={setSelectedCartItems}
                        setBillingSummary={setBillingSummary}
                        setSearchType={setSearchType}
                        isApproved={isApproved}
                    />
                </div>
                <div className="col-12 col-md-4 position-sticky">
                    <ECheckoutSummary
                        _handleCheckout={_handleCheckout}
                        cancelCart={cancelCart}
                        setBillingSummary={setBillingSummary}
                        billingSummary={billingSummary}
                        orderDetails={orderDetails}
                        isApproved={isApproved}
                        handleApproveOrder={() => handleApproveOrder(orderID)}
                        isCancelledOrder={isCancelledOrder}
                        handleChangeData={handleChangeData}
                    />
                </div>
            </div>


        </div>
    )
}

export default Index