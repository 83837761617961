import React from "react";
import "./Badges.scss";
import { BudgesProps } from "./interface";

const Index = ({ count, style, className }: BudgesProps) => {
    return (
        <>
            {count && count > 0 ? (
                <span
                    className={`${className ? className : "translate"} badge-style ${count > 9 ? "padding-double" : "padding-single"
                        }`}
                    style={style}
                >
                    {`${count ? count : 0}`}
                </span>
            ) : (
                ""
            )}
        </>
    );
};

export default Index;
