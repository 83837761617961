import React from 'react'
import atoms from '../../../atoms'
import moment from 'moment'
import { numberWithCommas } from '../../../../constants/utils'
import constant from '../../../../constants/constant'
import { useNavigate } from 'react-router-dom'
import { FaPrint } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import { RxUpdate } from "react-icons/rx";
import { MdOutlineDownloadDone } from "react-icons/md";

import { PiPlaceholderFill } from "react-icons/pi";
import ThreeDots from '../../../../assets/Images/threeDots.svg'

import { pendingOrderProps, ReceiptData } from './interface'
import service from '../../../../api/services'
const { Search, Table, Button, Pagination, Image } = atoms

const Index = ({ receiptData, setSearch, setLimitPerPage, setCurrentPage, currentPage, limit, handlePrintSharePdf, handleOnHoldOrder, handleReadyOrder }: pendingOrderProps) => {
    const navigate = useNavigate()



    const columns = [
        { name: 'Date', selector: (row: ReceiptData) => moment(row?.createdAt).format('DD/MM/YYYY'), width: '100px' },
        { name: 'Order Id', selector: (row: ReceiptData) => row?.order_id_display, width: '100px' },
        { name: 'Sold By', selector: (row: ReceiptData) => row?.staff.username, width: '150px' },
        { name: 'Customer Name', selector: (row: ReceiptData) => row?.customer.name || '-', width: '150px' },
        { name: 'Customer Contact', selector: (row: ReceiptData) => row?.customer.mobile || '-', width: '150px' },
        { name: 'Delivery Address', selector: (row: ReceiptData) => row?.delivery_address || '-', width: '150px' },
        {
            name: 'Total Amount(₹)', selector: (row: ReceiptData) => <>
                <div>
                    <p className='mb-1 ' >Order Value : {`₹${numberWithCommas(parseFloat((row.total_amount)?.toFixed(2)))}`}</p>
                    <p className='mb-1 '>Advance Paid : {`₹${numberWithCommas(parseFloat((row.total_paid_amount)?.toFixed(2)))}`}</p>
                    <p className='mb-1 '>Pending Amount : {`₹${numberWithCommas(parseFloat((row.total_amount - row.total_paid_amount)?.toFixed(2)))}`}</p>
                </div>
            </>, width: '180px'
        },
        {
            name: 'Total Items', selector: (row: ReceiptData) => <>
                <div>
                    <p className='mb-1 ' >Total Order : {row.total_order_items}</p>
                    <p className='mb-1 '>Delivered : {row.total_delivered_order_items}</p>
                    <p className='mb-1 '>Pending : {row.total_pending_order_items}</p>

                </div>
            </>, width: '120px'
        },
        { name: 'Status', selector: (row: any) => row?.order_status || '-', width: '150px' },
        { name: 'Action', selector: (row: any) => row?.action, center: true },


    ];



    return (
        <>
            <div className='row mt-md-0 mt-3'>
                <div className='col-md-6'>
                    <Search className='search search-style w-100' searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={receiptData?.data?.map((item: ReceiptData) => {
                            return ({ 
                                ...item,
                                order_status: item.order_status === constant.ORDER_STATUS.PENDING ? (
                                    <div className="background-light-yellow border-product-radius custom-badge"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : item.order_status === constant.ORDER_STATUS.DELIVERED ?
                                    (<div className="background-light-green border-product-radius custom-badge"><p className="color-green m-1 p-1">{"Delivered"}</p></div>) :
                                    item.order_status === constant.ORDER_STATUS.ON_HOLD ? (<div  className=" visible-overflow background-light-red border-product-radius custom-badge"
                                    ><p className="color-red m-1 p-1 text-nowrap">{"Hold Order Ready"}</p></div>)
                                        : item.order_status === constant.ORDER_STATUS.READY ? (
                                        <div className="background-light-green border-product-radius custom-badge visible-overflow" ><p className="text-nowrap color-green m-1 p-1">{"Order Ready"}</p></div>) : ''
                                ,
                                action: <>
                                    {<div className='table-data-width'>
                                        <button
                                            className="btn dropdown-dots shadow-none action-button"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <Image src={ThreeDots} />
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                            aria-labelledby="dropdownMenuButton1"
                                            style={{ width: "210px" }}
                                        >



                                            {/* Update Option */}
                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => { navigate(`/pending/order/${item.id}?pendingOrder`) }}>
                                                    <div className='d-flex align-items-center'>
                                                        <RxUpdate />
                                                        <span className='ms-2 mb-0'>Update</span>
                                                    </div>
                                                </button>
                                            </li>

                                            <hr className="my-1"></hr>
                                            {/* Download Option */}

                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => handlePrintSharePdf(item?.id)}>
                                                    <div className='d-flex align-items-center'>
                                                        <FaPrint />
                                                        <span className='ms-2 mb-0'>Print</span>
                                                    </div>
                                                </button>
                                            </li>
                                            <hr className="my-1"></hr>

                                            {/* Share Option */}
                                            <li>
                                                <button className="dropdown-item dropdown-item-size"
                                                    onClick={() => handlePrintSharePdf(item?.id, true)}>
                                                    <div className='d-flex align-items-center'>
                                                        <FiShare />
                                                        <span className='ms-2 mb-0'>Share</span>
                                                    </div>
                                                </button>
                                            </li>
                                            {item.order_status !== constant.ORDER_STATUS.ON_HOLD &&
                                                <>
                                                    <hr className="my-1"></hr>
                                                    <li>
                                                        <button className="dropdown-item dropdown-item-size"
                                                            onClick={() => handleOnHoldOrder(item?.id)}>
                                                            <div className='d-flex align-items-center'>
                                                                <PiPlaceholderFill />
                                                                <span className='ms-2 mb-0'> Hold Order Ready</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </>
                                            }
                                            {item.order_status !== constant.ORDER_STATUS.READY &&
                                                <>
                                                    <hr className="my-1"></hr>
                                                    <li>
                                                        <button className="dropdown-item dropdown-item-size"
                                                            onClick={() => handleReadyOrder(item?.id)}>
                                                            <div className='d-flex align-items-center'>
                                                                <MdOutlineDownloadDone size={'1.2em'} />
                                                                <span className='ms-2 mb-0'>Order Ready</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </>
                                            }


                                        </ul>
                                    </div>}
                                </>
                                // action:
                                //     <div className='d-flex'  >
                                //         <div className='col' style={{minWidth:"130px"}}>
                                //             <Button className='order' onClick={() => {navigate(`/pending/order/${item.id}?pendingOrder`)}}>Update</Button>
                                //         </div>
                                //         <div className='col'>
                                //             <Button className='order' onClick={() => generatePdf(item?.id)}>Print</Button>
                                //         </div>
                                //     </div>
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={receiptData?.totalPages}
                        onChangePage={(page: number) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    )
}

export default Index