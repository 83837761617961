import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { product } = Dictionary

const Product = {
    productList: async (paramsObj: {}, signal:any) => {
        return axios.post(BASE_URL + product.product(),paramsObj, { signal:signal})
    },
    list: async (payload: {}) => {
        return axios.post(BASE_URL + product.product(),payload)
    },
    filter: async () => {
        return axios.get(BASE_URL + product.filter())
    },
    productByID: async (id:string) => {
        return axios.get(BASE_URL + product.productById(id) )
    },
    productTypeList: async () => {
        return axios.get(BASE_URL + product.productTypeList() )
    },

    // for lens detail or heading
    lensDetails: async () => {
        return axios.get(BASE_URL + product.lensDetail())
    },
}
export default Product
