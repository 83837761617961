import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { ShowActiveTab } from '../../../../Context'
import organisms from '../../../../components/organisms'
import molecules from '../../../../components/molecules'
import service from '../../../../api/services'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import constant from '../../../../constants/constant'
import { useToasts } from 'react-toast-notifications'
import { handleFileDownload } from '../../../../constants/utils'
const { EInProcess } = organisms
const { TopBar, ConfirmationModal } = molecules
const Index = () => {
  const { addToast } = useToasts();
  const [receiptData, setReceiptData] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const { setActiveTab }: any = useContext(ShowActiveTab)

  const getFilterList = async () => {
    try {
      const response = await service.OnlineOrderService.onlineOrderList({ search: search, page: currentPage, limit: limit, status: constant?.ONLINE_ORDER_STATUS?.INPROGRESS })

      if (response.status === 200) {
        setReceiptData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCancelOrder = () => {
    setHandleDeleteModal(!handleDeleteModal)
  }

  const handlePrintPdf = async (id: string) => {
    try {
      const response = await service.SalesCart.generatePdf(id)

      if (response.status === 200) {
        handleFileDownload(response?.data?.data?.path)
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handleSharePdf = async (id: string, share: boolean) => {
    try {
      let response = await service.SalesCart.sharePdf(id, { share });
      addToast(response?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const storeDataInSession = (data: { name: string; mobile: number; totalPayable: number }) => {
    var accDetails = {
      name: data.name,
      mobile: data?.mobile,
      totalPayable: data?.totalPayable,
    }
    var accDetailsString = JSON.stringify(accDetails);
    sessionStorage.setItem('e_account_details', accDetailsString);
  }

  const handleReadyOrder = async (id: string) => {
    try {
      let response;
      response = await service.PendingOrder.ready(id);
      getFilterList()
      addToast(response?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });

    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handleOnHoldOrder = async (id: string) => {
    try {
      let response;
      response = await service.PendingOrder.onHold(id);
      getFilterList()
      addToast(response?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });

    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }


  useEffect(() => {
    getFilterList()
  }, [search, currentPage, limit])

  useEffect(() => {
    setActiveTab("e-inprogress")
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      getFilterList()
      setInitialLoading(true)
    }
  }, [limit])

  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='E-InProcess' />
      </div>
      <div className='mt-3'>
        <EInProcess
          receiptData={receiptData}
          setSearch={setSearch}
          limit={limit}
          currentPage={currentPage}
          setLimitPerPage={setLimit}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          handleReadyOrder={(id: string) => handleReadyOrder(id)}
          handlePrintSharePdf={(id: string) => handlePrintPdf(id)}
          handleSharePdf={(id:string, share:boolean)=> handleSharePdf(id, share)}
          handleOnHoldOrder={(id: string) => handleOnHoldOrder(id)}
          storeDataInSession={(data: { name: string; mobile: number; totalPayable: number }) => storeDataInSession(data)}
        />
      </div>
      {
        handleDeleteModal && <ConfirmationModal
          show={handleDeleteModal}
          handleConfirmItem={() => handleCancelOrder()}
          onHide={() => setHandleDeleteModal(!handleDeleteModal)}
          message={'cancel this order'} />
      }
    </Wrapper>
  )
}

export default Index