import './paymentSearch.scss'
import { MdOutlineVerified } from "react-icons/md"
import atoms from "../../../atoms";
import { numberWithCommas } from '../../../../constants/utils';
const { CustomImages } = atoms
const Index = ({ data, searchCallBack}: any) => {
    const handleSelect = (e: any) => {
        searchCallBack(e)
    }
    return (

        <ul className="custom-search card p-0">
            {data.length > 0 ? data?.map((item: any) => {
                return (
                    <li className="list-unstyled list-hover cursor-pointer px-3 d-flex">
                        <div className='fs-16 cursor-pointer col-9' onClick={() => handleSelect(item)}>

                            <h2 className="fs-14 fw-600 text-capitalize">{item?.brand?.brand_name}</h2>
                            <div className='d-flex'>
                                <h3 className="fs-12 fw-600 secondary-color">Customer Name : </h3>
                                <h3 className="fs-12 fw-600 secondary-color" style={{ marginLeft: "10px" }}>{item?.gl_account_name}</h3>
                            </div>
                            <div className='d-flex' >
                                <h3 className="fs-12 fw-600 secondary-color" >GL Number : </h3>
                                <h3 className=" fs-12 fw-600 secondary-color" style={{ marginLeft: "10px" }}>{item?.gl_number}</h3>
                            </div>
                            <div className='d-flex' >
                                <h3 className="fs-12 fw-600 secondary-color" >Balance : </h3>
                                <h3 className=" fs-12 fw-600 secondary-color" style={{ marginLeft: "10px" }}>₹{numberWithCommas(parseFloat((-item?.balance)?.toFixed(2)))}</h3>
                            </div>
                        </div>
                    </li>
                )
            }) :
                <li className="list-unstyled list-hover cursor-pointer p-1 px-3 d-flex">
                    <div className='fs-16 cursor-pointer col-11'>
                        <div className="fw-500 text-capitalize py-3">
                            No product found
                        </div>
                    </div>
                </li>
            }
        </ul>
    )
}
export default Index