import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { ShowActiveTab } from '../../../../Context'
import organisms from '../../../../components/organisms'
import molecules from '../../../../components/molecules'
import service from '../../../../api/services'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import constant from '../../../../constants/constant'
import { useToasts } from 'react-toast-notifications'
import { handleFileDownload } from '../../../../constants/utils'
const { ESalesHistory } = organisms
const { TopBar, ConfirmationModal } = molecules
const Index = () => {
  const { addToast } = useToasts();
  const [receiptData, setReceiptData] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
  const [orderItemId, setOrderItemId] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const { setActiveTab }: any = useContext(ShowActiveTab)

  const getFilterList = async () => {
    try {
      const response = await service.OnlineOrderService.onlineOrderList({ search: search, page: currentPage, limit: limit, status: constant?.ONLINE_ORDER_STATUS?.COMPLETED })

      if (response.status === 200) {
        setReceiptData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCancelOrder = () => {
    setHandleDeleteModal(!handleDeleteModal)
  }

  const handleEPrint = async (id: string) => {
    try {
      const response = await service.SalesCart.generatePdf(id)

      if (response.status === 200) {
        handleFileDownload(response?.data?.data?.path)
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handlePrintSharePdf = async (id: string, flag: boolean) => {
    try {
      let response;
      if (flag) {
        response = await service.SalesCart.sharePdf(id, { flag });
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      } else {
        response = await service.SalesCart.generatePdf(id);
        if (response.status === 200) {
          handleFileDownload(response?.data?.data?.path)
        }
      }

    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }


  useEffect(() => {
    getFilterList()
  }, [search, currentPage, limit])

  useEffect(() => {
    setActiveTab("e-cancelled")
    // setCurrentPage(1)
  }, [])

  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='E-Sales History' />
      </div>
      <div className='mt-3'>
        <ESalesHistory
          receiptData={receiptData}
          setSearch={setSearch}
          limit={limit}
          currentPage={currentPage}
          setLimitPerPage={setLimit}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          handlePrintSharePdf={(id: string, flag: boolean) => handlePrintSharePdf(id, flag)}
          handleEPrint={(id: string) => handleEPrint(id)}
        />
      </div>
      {
        handleDeleteModal && <ConfirmationModal
          show={handleDeleteModal}
          handleConfirmItem={() => handleCancelOrder()}
          onHide={() => setHandleDeleteModal(!handleDeleteModal)}
          message={'cancel this order'} />
      }
    </Wrapper>
  )
}

export default Index