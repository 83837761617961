import React, { useState, useEffect, useContext, useRef } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import organisms from '../../../components/organisms'
import molecules from '../../../components/molecules'
import { ShowActiveTab } from '../../../Context';
import { useToasts } from 'react-toast-notifications';
import constant from '../../../constants/constant';
import service from '../../../api/services'
import { BarcodeDataProps } from './interface';

const { InventoryAudit } = organisms
const { TopBar } = molecules


const Index = () => {
    const { setActiveTab }: any = useContext(ShowActiveTab)
    const searchTabContainer = useRef<HTMLDivElement>(null);
    const [barcodeData, setBarcodeData] = useState<BarcodeDataProps | null>(null)
    const [inventoryItemCondition, setInventoryItemCondition] = useState()
    const [updateData, setUpdateData] = useState<any>({
        item_condition: [],
        conditionRemark: "",
    })
    const { addToast } = useToasts();


    const getDropdown = (options: any, threshold: number) => {
        let dropdown: any = [];

        if (threshold) {
            options.map((item: { label: string, value: number }) => {
                if (item?.value >= threshold) {
                    dropdown.push({
                        "value": item.value,
                        "label": item.label
                    });
                }
            });
        } else {
            return constant?.INVENTORY_ITEM_CONDITION;
        }

        return dropdown;
    };


    const handleBarcodeSearch = async (search: string) => {
        try {
            const response = await service.Audit.barcodeSearchApi({ search: search })
            if (response.status === 200) {
                setBarcodeData(response?.data?.data)
                setUpdateData({ ...updateData, item_condition: {}, conditionRemark: "" })
                setInventoryItemCondition(getDropdown(constant?.INVENTORY_ITEM_CONDITION, response?.data?.data?.audit_trails[0]?.new_audit_status))
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });

        }
    }

    const updateItemCondition = async (id: string) => {
        const payload = {
            "item_condition": updateData.item_condition.value,
            "condition_remark": updateData.conditionRemark
        }
        try {
            const response = await service.Audit.updateItemCondition(id, payload)
            if (response.status === 200) {
                // handleBarcodeSearch(searchValue)
                setBarcodeData(null)
                addToast(response?.data?.message, {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                if (searchTabContainer?.current) {
                    const inputElement = searchTabContainer.current.querySelector('input');
                    if (inputElement) {
                        (inputElement as HTMLInputElement).value = '';
                    }
                }
            }
        } catch (error: any) {
            console.log('error', error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    const handleChange = (name: string, value: any) => {
        setUpdateData({ ...updateData, [name]: value })
    }

    useEffect(() => { setActiveTab("InventoryAudit") }, [])
    return (
        <Wrapper>
            <div className=''>
                <div className='row align-items-center'>
                    <TopBar heading={'Inventory Audit'} />
                </div>
                <InventoryAudit
                    handleBarcodeSearch={(search: string) => handleBarcodeSearch(search)}
                    updateItemCondition={updateItemCondition}
                    barcodeData={barcodeData}
                    handleChange={handleChange}
                    updateData={updateData}
                    inventoryItemCondition={inventoryItemCondition}
                    searchTabContainer={searchTabContainer}
                />
            </div>
        </Wrapper>
    )
}

export default Index