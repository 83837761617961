import React, { useEffect, useState } from 'react'
import Wrapper from '../../../components/common/Wrapper/Wrapper'
import molecules from '../../../components/molecules';
import organisms from '../../../components/organisms';
import { IoMdCopy } from "react-icons/io";
import service from '../../../api/services';
import { useParams } from "react-router-dom";
import { Button } from 'reactstrap';
import constant from '../../../constants/constant';
import { calculateDeliveryDate } from '../../../constants/utils';
const { TopBar } = molecules
const { PendingOrder } = organisms

const Index = () => {
  const [orderDetails, setOrderDetails] = useState<any>()
  const [searchProduct, setSearchProduct] = useState<string>('')
  const [productList, setProductList] = useState<string>('')
  const [searchType, setSearchType] = useState<string>('barcode')

  let params = useParams();
  let orderID = params.id || ''
  const [billingSummary, setBillingSummary] = useState({
    subTotal: NaN,
    roundOff: NaN,
    totalSales: NaN,
    totalDiscount: NaN,
    total_paid_amount: NaN,
    loyalty_points: NaN,
    loyalty_point_limit: NaN,
    redeemed_points: NaN,
    totalSalesToShow: NaN,
    order_status: NaN,
    redemption_request: false,
    deliveryDate:'',
  })

  const [selectedCartItems, setSelectedCartItems] = useState([{
    order_item_id: '',
    discount: '',
    product_srp: '',
    isDelivered: '',
    inventory_item_id: ''
  }])

  const getOrderDetails = async (redemption_request?: boolean) => {
    try {
      const response = await service.PendingOrder.viewOrderDetails(orderID);
      if (response.status === 200) {
        handlePreviousDiscount(response.data.data)
        // setOrderDetails(response.data.data)
        let total = (response.data.data?.order_items || []).reduce((sum: number,
          item: { product: { product_srp: number }, other_product_data: { price: number } }
        ) => sum + (item?.product?.product_srp || item?.other_product_data?.price), 0);
        setBillingSummary({
          ...billingSummary,
          subTotal: total,
          totalSales: total,
          roundOff: response.data.data.round_off_amount,
          total_paid_amount: response.data.data.total_paid_amount,
          redeemed_points: response?.data?.data?.redeemed_points,
          order_status: response?.data?.data?.order_status,
          loyalty_point_limit: response?.data?.minimum_redeemable_points,
          loyalty_points: Math.min(response?.data?.data?.customer?.loyalty_points, ((total - response.data.data.total_paid_amount) - response.data.data.round_off_amount - (response?.data?.data?.order_items?.reduce((sum: number, item: any) => sum + (item?.discount || 0), 0)))),
          totalSalesToShow: total,
          deliveryDate: calculateDeliveryDate(response?.data?.data?.store?.estimated_delivery_days),
          redemption_request: redemption_request === false ? redemption_request : response.data.data.redemption_request
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handlePreviousDiscount = (cardDataCopy: any) => {
    const updatedArr1 = cardDataCopy && cardDataCopy?.order_items?.map((item1: any) => {
      const matchingItem2 = selectedCartItems?.find((item2) => item1.id === item2.order_item_id);
      if (matchingItem2) {
        // If there is a match, update the discount value
        return { ...item1, discount: matchingItem2.discount, isDelivered: (item1.status == constant.ORDER_ITEM_STATUS.DELIVERED || item1.status == constant.ORDER_ITEM_STATUS.READY_TO_DELIVERED) ? true : false };
      }
      // If there is no match, keep the original item
      return { ...item1, discount: item1.discount > 0 ? item1.discount : null, isDelivered: (item1.status == constant.ORDER_ITEM_STATUS.DELIVERED || item1.status == constant.ORDER_ITEM_STATUS.READY_TO_DELIVERED) ? true : false };
    });
    setOrderDetails({ ...cardDataCopy, order_items: updatedArr1, searchType: searchType })
  }
  const _productSearch = async () => {
    try {
      const response = await service.Product.list({ [searchType]: searchProduct });
      if (response.status === 200) {
        setProductList(response.data.data)
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleChangeData = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    const currentDate = new Date();

    if (selectedDate >= currentDate) {
      setBillingSummary({
        ...billingSummary,
        deliveryDate: selectedDate.toISOString().split("T")[0]
      })
    }
  };
  useEffect(() => {
    searchProduct !== '' && _productSearch()
  }, [searchProduct])

  useEffect(() => {
    getOrderDetails()
  }, [])

  useEffect(() => {
    setOrderDetails({ ...orderDetails, searchType: searchType })
  }, [searchType])

  return (
    <Wrapper>
      <div className='d-md-flex justify-content-between'>
        <TopBar heading='Cart' />
        <p className='fs-16 fw-600 mt-md-0 mt-3'>Order ID :-  <Button className="table-edit-button fs-14 fw-400 px-3 py-2 mx-2"
          style={{ background: 'transparent', color: 'black', border: '1px solid #D9D9D9' }}>
          <div className='d-flex align-items-center'>
            <span className='ms-2 mb-0' style={{ color: '#505D6F' }}>{orderDetails?.order_id_display}</span>
            <IoMdCopy className='fs-20 mx-3' style={{ color: '#d9d9d9' }} />
          </div>
        </Button>
        </p>
      </div>
      <PendingOrder
        orderDetails={orderDetails}
        setSearchProduct={setSearchProduct}
        productList={productList}
        getOrderDetails={getOrderDetails}
        setBillingSummary={setBillingSummary}
        billingSummary={billingSummary}
        selectedCartItems={selectedCartItems}
        setSelectedCartItems={setSelectedCartItems}
        setSearchType={setSearchType}
        handleChangeData={handleChangeData}
      />
    </Wrapper>
  )
}

export default Index