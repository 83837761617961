import React, { useEffect, useState, useContext } from 'react'
import Wrapper from '../../../../components/common/Wrapper/Wrapper'
import { ShowActiveTab } from '../../../../Context'
import organisms from '../../../../components/organisms'
import molecules from '../../../../components/molecules'
import service from '../../../../api/services'
import { Default_Page_Limit } from '../../../../constants/defaultValues'
import constant from '../../../../constants/constant'
import { useToasts } from 'react-toast-notifications'
import { handleFileDownload } from '../../../../constants/utils'
const { EPendingOrder } = organisms
const { TopBar, ConfirmationModal } = molecules
const Index = () => {
  const { addToast } = useToasts();
  const [receiptData, setReceiptData] = useState<any>()
  const [initialLoading, setInitialLoading] = useState<boolean>(false)
  const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
  const [handleApproveModal, setHandleApproveModal] = useState(false)
  const [orderItemId, setOrderItemId] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState(Default_Page_Limit);
  const { setActiveTab }: any = useContext(ShowActiveTab)

  const getFilterList = async () => {
    try {
      const response = await service.OnlineOrderService.onlineOrderList({ search: search, page: currentPage, limit: limit, status: constant?.ONLINE_ORDER_STATUS?.PENDING })

      if (response.status === 200) {
        setReceiptData(response.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCancelOrder = async () => {
    try {
      const response = await service.PendingOrder.cancelOrder(orderItemId)
      if (response.status === 200) {
        if (response.status === 200 || response.status === 201) {
          setHandleDeleteModal(!handleDeleteModal)
          getFilterList()
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
        }
      }
    } catch (error:any) {
      console.log('error', error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handleApproveOrder = async () => {
    try {
      const response = await service.OnlineOrderService.approvedOnlineOrder(orderItemId)
      if (response.status === 200 || response.status === 201) {
        setHandleApproveModal(!handleApproveModal);
        getFilterList()
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handleView = async (id: string) => {
    try {
      const response = await service.SalesCart.generatePdf(id)
      // await service.OnlineOrderService.downloadOrder(id)

      if (response.status === 200) {
        handleFileDownload(response?.data?.data?.path)
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }


  useEffect(() => {
    getFilterList()
  }, [search, currentPage, limit])

  useEffect(() => {
    setActiveTab("e-pending")
    setCurrentPage(1)
    if (currentPage == 1 && initialLoading) {
      getFilterList()
      setInitialLoading(true)
    }
  }, [limit])


  return (
    <Wrapper>
      <div className='row align-items-center'>
        <TopBar heading='E-Pending Order' />
      </div>
      <div className='mt-3'>
        <EPendingOrder
          receiptData={receiptData}
          setSearch={setSearch}
          limit={limit}
          currentPage={currentPage}
          setLimitPerPage={setLimit}
          setCurrentPage={(page: number) => setCurrentPage(page)}
          handleView={(id: string) => handleView(id)}
          handleCancel={(id: string) => { setHandleDeleteModal(!handleDeleteModal); setOrderItemId(id); }}
          handleApproveOrder={(id: string) => { setHandleApproveModal(!handleApproveModal); setOrderItemId(id); }}
        />
      </div>
      {
        handleDeleteModal && <ConfirmationModal
          show={handleDeleteModal}
          handleConfirmItem={() => handleCancelOrder()}
          onHide={() => { setOrderItemId(''); setHandleDeleteModal(!handleDeleteModal); }}
          message={'cancel this order'} />
      }

      {
        handleApproveModal && <ConfirmationModal
          show={handleApproveModal}
          handleConfirmItem={() => handleApproveOrder()}
          onHide={() => { setOrderItemId(''); setHandleApproveModal(!handleApproveModal); }}
          message={'approve this online order'} />
      }
    </Wrapper>
  )
}

export default Index