import React from 'react';
import { InputInterface } from './interface';
import './Input.scss';

const Input: React.FC<InputInterface> = ({
    id, type, name, disabled, defaultValue,
    onChange, errors, className, placeholder, label,
    htmlFor, accept, multiple, value, checked, required,
    ref, maxLength, labelStyle, radioInputClass,min
}) => {
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (type === 'number') {
            const invalidKeys = ['ArrowUp', 'ArrowDown', 'e'];
            if (invalidKeys.includes(event.key)) {
                event.preventDefault();
            }
        }
    };

    const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (type === 'number') {
            (event.target as HTMLInputElement).blur();
        }
    };

    return (
        <div className={`form-group ${className}`}>
            {label && <label className={`inputs-label mb-2 fw-600 ${labelStyle ? labelStyle : 'fs-16'} secondary-color`} htmlFor={htmlFor}>{label}</label>}
            <input
                id={id}
                className={`${type === "radio" ? radioInputClass : "inputs form-control "} shadow-none`}
                type={type}
                name={name}
                defaultValue={defaultValue}
                onChange={onChange}
                placeholder={placeholder}
                accept={accept}
                multiple={multiple}
                value={value}
                checked={checked}
                required={required}
                ref={ref}
                onKeyDown={handleKeyDown}
                onWheel={handleWheel}
                disabled={disabled}
                maxLength={maxLength}
            />
            {errors && <span className="error-text">{errors}</span>}
        </div>
    );
};

export default Input;
