import { useEffect, useState, useRef } from 'react'
import BrandPlaceHolder from '../../../assets/Images/placeholderimg.png';
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import atoms from '../../atoms';
import service from '../../../api/services';
import { calculateEyeConditions, determineEyeType, findConstantLabel, getDropdown, isValidProductType, numberWithCommas, sumTwoNumbers, } from '../../../constants/utils';
import SearchTab from '../SearchTab';
import constant from '../../../constants/constant';
import ConfirmationModal from '../ConfirmationModal';
import { useToasts } from 'react-toast-notifications';
import { IAdditionalProduct, SelectedHeadingProps } from './interface';
import CreateModal from '../CreateModal';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { MdOutlineAddToPhotos } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { Contact_lens } from '../../../constants/defaultValues';

const { Select, Search, Image, Input, Checkbox, AvailableBadge, UnavailableBadge, TextArea, CustomImages, RadioInput, Button } = atoms
const CheckoutCard = ({ product, orderDetails, onUpdateDiscount, getViewCart, setSelectedEyeType, setSelectedProductId, setBackupEyeDetail, setSelectedCartItems, setShowContactLensModal, setShowModal, setAdditionalProduct, order_status, setBillingSummary, sales_history_screen, setIsAdditionalProductEdit }: any) => {
  let initialDiscount = product?.discount && product?.discount?.toFixed(2)
  const [discountAmount, setDiscountAmount] = useState<number>(initialDiscount);
  const [discountPercentage, setDiscountPercentage] = useState<number>(NaN);
  const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
  const [showProductDetail, setShowProductDetail] = useState<boolean>(false)
  const [showLensDetail, setShowLensDetail] = useState<boolean>(false)
  const [removeProductId, setRemoveProductId] = useState<string>('')
  const [disAmount, setDisAmount] = useState<number>(NaN)
  const { addToast } = useToasts();

  const repeatItem = async (orderItemId: string) => {

    try {
      await service.PendingOrder.repeatItem(orderItemId)
      getViewCart(false)
    } catch (error: any) {
      console.log('error', error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const _handleEditProduct = async (selectedItem: any) => {
    const productData = {
      product_type_id: selectedItem?.other_product_data?.product_type_id,
      product_type_name: selectedItem?.other_product_data?.product_type_name,
      price: selectedItem?.other_product_data?.price,
      description: selectedItem?.other_product_data?.description,
      isAdditional: selectedItem?.is_other_product,
      name: selectedItem?.other_product_data?.lens_details?.name,
      phone: selectedItem?.other_product_data?.lens_details?.phone,

      // Right eye details
      r_dv_sph: selectedItem?.other_product_data?.lens_details?.r_dv_sph,
      r_dv_cyl: selectedItem?.other_product_data?.lens_details?.r_dv_cyl,
      r_dv_axis: selectedItem?.other_product_data?.lens_details?.r_dv_axis,
      r_dv_pd: selectedItem?.other_product_data?.lens_details?.r_dv_pd,
      r_dv_va: selectedItem?.other_product_data?.lens_details?.r_dv_va,
      r_nv_sph: selectedItem?.other_product_data?.lens_details?.r_nv_sph,
      r_nv_cyl: selectedItem?.other_product_data?.lens_details?.r_nv_cyl,
      r_nv_axis: selectedItem?.other_product_data?.lens_details?.r_nv_axis,
      r_nv_pd: selectedItem?.other_product_data?.lens_details?.r_nv_pd,
      r_add: selectedItem?.other_product_data?.lens_details?.r_add,

      // Left eye details
      l_dv_sph: selectedItem?.other_product_data?.lens_details?.l_dv_sph,
      l_dv_cyl: selectedItem?.other_product_data?.lens_details?.l_dv_cyl,
      l_dv_axis: selectedItem?.other_product_data?.lens_details?.l_dv_axis,
      l_dv_pd: selectedItem?.other_product_data?.lens_details?.l_dv_pd,
      l_dv_va: selectedItem?.other_product_data?.lens_details?.l_dv_va,
      l_nv_sph: selectedItem?.other_product_data?.lens_details?.l_nv_sph,
      l_nv_cyl: selectedItem?.other_product_data?.lens_details?.l_nv_cyl,
      l_nv_axis: selectedItem?.other_product_data?.lens_details?.l_nv_axis,
      l_nv_pd: selectedItem?.other_product_data?.lens_details?.l_nv_pd,
      l_add: selectedItem?.other_product_data?.lens_details?.l_add,

      isEdit: true,
      productId: selectedItem?.id,
    };

    // Set additional product details
    await setAdditionalProduct(productData);

    // Set backup eye details
    await setBackupEyeDetail(productData);

    // Update state
    setSelectedProductId(selectedItem?.id);
    setSelectedEyeType(selectedItem?.other_product_data?.lens_details?.selectedEyeType);
    setShowModal(true);
    setIsAdditionalProductEdit(true)
  };

  const removeItem = async () => {
    try {
      await service.PendingOrder.removeItem(removeProductId)
      setHandleDeleteModal(!handleDeleteModal)
      getViewCart(false)
    } catch (error) {
      console.log('error', error)
    }
  }
  const calculateDiscountPercentage = (amount: number, product_srp: number) => {
    setBillingSummary((preV: any) => {
      return { ...preV, redemption_request: false };
    });
    let p = (amount / product_srp) * 100
    let percentage: any = p > 0 ? p : null
    setDiscountPercentage(parseFloat(percentage?.toFixed(2)));
    setDiscountAmount(parseFloat(amount?.toFixed(2)));
    setDisAmount(amount)
    onUpdateDiscount(product.id, percentage, amount);
  };

  useEffect(() => {
    let srp_price = product?.product?.product_srp || product?.other_product_data?.price
    calculateDiscountPercentage(product?.discount, srp_price)
  }, [product?.discount, product?.other_product_data?.price])

  const calculateDiscountAmount = (percentage: number, product_srp: number) => {
    setBillingSummary((preV: any) => {
      return { ...preV, redemption_request: false };
    });
    let amount = (percentage / 100) * product_srp
    setDiscountAmount(parseFloat(amount?.toFixed(2)));
    setDiscountPercentage(percentage);
    setDisAmount(amount)
    onUpdateDiscount(product.id, percentage, amount);
  };
  const _handleRemove = (productId: string) => {
    setHandleDeleteModal(!handleDeleteModal)
    setRemoveProductId(productId)

  }
  const _handleBarcode = (barcode: any) => {
    product.inventory_item = barcode
    setSelectedCartItems((prevArray: any) =>
      prevArray?.map((obj: any) =>
        (obj.isDelivered == true && obj?.order_item_id === product.id)
          ? { ...obj, inventory_item_id: barcode?.value }
          : obj
      )
    )
  }

  const _handleIsDelivered = (e: any) => {
    let isChecked = e.target.checked
    setSelectedCartItems((prevArray: any) => {
      const newArray = prevArray?.map((obj: any) =>
        obj.order_item_id === product.id
          // ? { ...obj, isDelivered: isChecked }
          ? { ...obj, inventory_item_id: isChecked ? obj.inventory_item_id : '', isDelivered: isChecked }
          : obj
      )
      return newArray;
    }
    )
    if (product.id) {
      product.isDelivered = isChecked;
      // product.inventory_item_id = isChecked ? product.inventory_item_id : '';
    }
  }
  useEffect(() => {

    let x = ((product?.product?.barcodes.length > 1) && (!product?.inventory_item))
      ? null // If there are multiple barcodes, don't prefill
      : {
        key: product?.inventory_item?.id || product?.inventory_item?.key || product?.product?.barcodes[0]?.id,
        value: product?.inventory_item?.id || product?.inventory_item?.value || product?.product?.barcodes[0]?.id,
        label: product?.inventory_item?.bar_code || product?.inventory_item?.label || product?.product?.barcodes[0]?.bar_code
      }
    _handleBarcode(x)
  }, [product.isDelivered])

  const handleProductClick = () => {
    setSelectedProductId(product?.id);
    setSelectedEyeType('');
    setShowContactLensModal(true);
    setShowModal(true);
    // setAdditionalProduct({
    //     name: customerInfo?.name || '',
    //     phone: customerInfo?.mobile || '',
    // });
    setAdditionalProduct({
      product_type_id: '',
      price: '',
      description: '',
      isAdditional: true,
      name: orderDetails?.customer?.name || '',
      phone: orderDetails?.customer?.mobile || '',
      r_dv_sph: "",
      r_dv_cyl: "",
      r_dv_axis: "",
      r_dv_pd: "",
      r_add: "",
      r_nv_sph: "",
      r_nv_cyl: "",
      r_nv_axis: "",
      r_nv_pd: "",
      l_dv_sph: "",
      l_dv_cyl: "",
      l_dv_axis: "",
      l_dv_pd: "",
      l_nv_sph: "",
      l_nv_cyl: "",
      l_nv_axis: "",
      l_nv_pd: "",
      l_add: "",
      isEdit: false,
      productId: ''
    });
    setBackupEyeDetail({
      product_type_id: '',
      price: '',
      description: '',
      isAdditional: true,
      name: orderDetails?.customer?.name || '',
      phone: orderDetails?.customer?.mobile || '',
      r_dv_sph: "",
      r_dv_cyl: "",
      r_dv_axis: "",
      r_dv_pd: "",
      r_add: "",
      r_nv_sph: "",
      r_nv_cyl: "",
      r_nv_axis: "",
      r_nv_pd: "",
      l_dv_sph: "",
      l_dv_cyl: "",
      l_dv_axis: "",
      l_dv_pd: "",
      l_nv_sph: "",
      l_nv_cyl: "",
      l_nv_axis: "",
      l_nv_pd: "",
      l_add: "",
      isEdit: false,
      productId: ''
    });
  };
  return (
    <>
      {(!product.is_other_product) ?
        // ================== For Regular Products================

        <div className='checkout-product-card mt-md-4 mt-2'>
          <div className='d-md-flex flex-wrap  align-items-center justify-content-between '>
            <div className='d-flex flex-wrap gap-3 align-items-center'>
              <div className='d-none d-md-flex flex-wrap align-items-center gap-3'>
                <h2 className='mb-0 fs-14 fw-600'>Product ID :-</h2>
                <div className='id-input-design d-flex justify-content-between align-items-center gap-2'>
                  <h2 className='mb-0 fs-12 fw-400'>{product?.product?.product_id_display}</h2>
                  <Image src={'Copy'} alt="" className='img-fluid' />
                </div>
              </div>
              <div className='d-md-none d-flex justify-content-between w-100 align-items-center'>
                <div>
                  <h2 className='mb-0 fs-16 fw-600'>{product?.product?.brand?.brand_name}</h2>
                </div>
                <div className='d-flex gap-2 text-sm-start text-md-start text-lg-end mt-1 mt-md-0 align-items-center'>
                  {(discountAmount > 0 || (discountPercentage || 0) > 0) && <p className='mb-0 fs-md-16 fs-12 fw-500 dark-gray text-decoration-line-through'>₹{product?.product?.product_srp}</p>}
                  <p className='mb-0 fs-md-18 fs-14 fw-600 dark-gray'>₹{disAmount > 0 ? numberWithCommas(parseFloat((product?.product?.product_srp - disAmount)?.toFixed(2))) : numberWithCommas(parseFloat(product?.product?.product_srp?.toFixed(2)))}</p>
                </div>
              </div>

            </div>
            {!(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY) && <>
              <div>
                {((order_status == constant.ORDER_ITEM_STATUS.DELIVERED) ||
                  product.availableAtStore) &&
                  <div className='d-flex flex-wrap align-items-center gap-3'>
                    <Checkbox className='checkbox-style cursor-pointer'
                      onChange={(e) => _handleIsDelivered(e)}
                      id={'idDelivered'}
                      label={'Product Is Delivered'}
                      checked={product?.isDelivered}
                      labelClass='fs-md-16 fs-12 ms-2'
                      name={'idDelivered'} />
                  </div>
                }
              </div>

              {!(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY) &&
                // ((order_status === constant.ORDER_ITEM_STATUS.DELIVERED)) ||
                //   product.availableAtStore) &&
                // product.status !== constant.ORDER_ITEM_STATUS.DELIVERED &&
                <div className={`d-none d-md-flex  flex-wrap align-items-center gap-3 margin_top_10
                  ${((order_status == constant.ORDER_ITEM_STATUS.DELIVERED) ||
                    product.availableAtStore) && 'button-position'}`}>
                  {(product?.product?.is_contact_lens && !product?.other_product_data) && <h2 className='mb-0 fs-md-14 fs-10 fw-500 text-decoration-underline cursor-pointer'
                    onClick={() => handleProductClick()}
                  >Add Prescription</h2>}
                  {(product?.product?.is_contact_lens && product?.other_product_data) && <h2 className='mb-0 fs-md-14 fs-10 fw-500 text-decoration-underline cursor-pointer' onClick={() => { _handleEditProduct(product); setShowContactLensModal(true); }}>Edit Prescription</h2>}
                  <h2 className='mb-0 fs-md-14 fs-10 fw-500 text-decoration-underline cursor-pointer' onClick={() => _handleRemove(product?.id)}>Remove</h2>
                  <h2 className='mb-0 fs-md-14 fs-10 fw-500 text-decoration-underline cursor-pointer' onClick={() => repeatItem(product?.id)}>Repeat</h2>
                </div>
              }
            </>}

          </div>
          <div className="row ">
            {product.availableAtStore && <div className="sales-card-badge">
              <AvailableBadge />
            </div>}
            {product.status === constant.ORDER_ITEM_STATUS.DELIVERED && <div className="sales-card-badge">
              <AvailableBadge title={'Delivered'} />
            </div>}
            {product.status === constant.ORDER_ITEM_STATUS.SALES_RETURN && <div className="sales-card-badge">
              <UnavailableBadge title={'Returned'} />
            </div>}
            <div className="col-5 col-md-3 mt-4">
              <div className='sales-product-image' style={{ overflow: "hidden" }}>
                <div className=''>
                  <CustomImages imageHeight='100px' src={product?.product?.images[0] || BrandPlaceHolder} />
                </div>
                <div className='d-md-none row'>
                  <div className='col-6 d-flex justify-content-center border p-1' onClick={() => _handleRemove(product?.id)}>
                    <RiDeleteBin6Line className='fs-20' style={{ color: "red" }} />
                  </div>
                  <div className='col-6 d-flex justify-content-center border p-1' onClick={() => repeatItem(product?.id)}>
                    <MdOutlineAddToPhotos className='fs-20' />
                  </div>
                </div>
              </div>
              {/* <CustomImages imageHeight='110px' src={product?.product?.images[0]} /> */}
              {/* <div style={{ backgroundImage: `url(${product?.product?.images[0] || BrandPlaceHolder})` }}
                className='img-fluid product-image ' /> */}
            </div>

            <div className="col-7 col-md-9 mt-4 ps-1">
              <div className='d-md-none d-flex flex-wrap align-items-center gap-1'>
                <h2 className='mb-0 fs-md-14 fs-10 fw-600'>Product ID :-</h2>
                <h2 className='mb-0 fs-md-12 fs-10 fw-400 dark-gray'>{product?.product?.product_id_display}</h2>
              </div>
              <div className='d-md-none mt-2'>
                {<div>
                  <div className='d-flex gap-1 mb-1'>
                    <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Model {window.innerWidth < 768 ? 'No' : 'Number'}: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.modal_number}</span></h2>
                    <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Size/Power: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.glass_size}MM</span></h2>
                  </div>
                  <div className='d-flex gap-1 mb-1'>
                    <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Warranty: <span className='mb-0 fs-md-14 fs-11 fw-600'>{findConstantLabel(constant.PRODUCT_WARRANTY, product?.product?.warranty)}</span></h2>
                    <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Polarized: <span className='mb-0 fs-md-14 fs-11 fw-600'>{findConstantLabel(constant?.POLARIZED, product?.product?.isPolarised)}</span></h2>
                  </div>
                </div>}
                {showProductDetail &&
                  <>
                    <div className='d-flex gap-1 mb-1'>
                      <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>With Case: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.product_with_case ? "Yes" : "No"} </span></h2>
                      <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'>Color Code: <span className='mb-0 fs-md-14 fs-11 fw-600'>{product?.product?.brand_color_code}</span></h2>
                    </div>
                    <div className="gap-1 align-items-center px-0">
                      <h2 className='mb-0 fs-md-14 fs-11 fw-500 dark-gray'><span className=''>{product?.product?.description}</span></h2>
                    </div>
                  </>
                }
                <div className='d-md-none text-start'>
                  <a className='fs-10' style={{ minWidth: "40px" }} onClick={() => setShowProductDetail(!showProductDetail)}>{!showProductDetail ? "see more" : "see less"}</a>
                </div>
              </div>
              <div className='d-none d-md-flex flex-wrap justify-content-between align-items-end '>
                <div className={` ${window.innerWidth > 768 ? "w-75" : "w-100"}`}>
                  <h2 className='d-none d-md-flex mb-0 fs-16 fw-600 dark-gray'>{product?.product?.brand?.brand_name}</h2>
                  {!product?.product?.is_contact_lens && <div className="d-flex gap-md-3 gap-2 align-items-center my-2 ">
                    <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Model {window.innerWidth < 768 ? 'No' : 'Number'} : <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.modal_number}</span></h2>
                    <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Warranty: <span className='mb-0 fs-md-14 fs-10 fw-600'>{findConstantLabel(constant.PRODUCT_WARRANTY, product?.product?.warranty)}</span></h2>
                    <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Polarized: <span className='mb-0 fs-md-14 fs-10 fw-600'>{findConstantLabel(constant?.POLARIZED, product?.product?.isPolarised)}</span></h2>
                  </div>}

                  {!product?.product?.is_contact_lens && <div className="d-flex gap-3 align-items-center mb-2">
                    <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Color Code: <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.brand_color_code}</span></h2>
                    <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>With Case: <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.product_with_case ? "Yes" : "No"} </span></h2>
                    <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'>Size/Power: <span className='mb-0 fs-md-14 fs-10 fw-600'>{product?.product?.glass_size}MM</span></h2>
                  </div>}
                  <div className="d-flex gap-3 align-items-center mb-2">
                    <h2 className='mb-0 fs-md-14 fs-10 fw-500 dark-gray'><span className=''>{product?.product?.description}</span></h2>
                  </div>
                  {(product?.other_product_data?.lens_details && product?.product?.is_contact_lens) && <div className=''>
                    <h3 className='fs-md-14 fs-11 fw-500 dark-gray mb-0'>Name : {product?.other_product_data?.lens_details?.name}</h3>
                    <h3 className='fs-md-14 fs-11 fw-500 dark-gray'>Phone : {product?.other_product_data?.lens_details?.phone} </h3>
                    <div className="d-none d-md-block  card p-3 mb-2">
                      <div className="row">
                        {(product?.other_product_data?.lens_details?.selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.RIGHT_EYE || product?.other_product_data?.lens_details?.selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) &&
                          <div className={`col-12 ${product?.other_product_data?.lens_details?.selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE && 'col-md-6'}`}>
                            <h3 className='text-center fs-16 fw-600 dark-gray'>{`Right Eye (OD)`}</h3>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray">SPH</div>
                              <div className="col fs-12 fw-500 dark-gray">CYL</div>
                              <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                              <div className="col fs-12 fw-500 dark-gray">PD</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">DV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">NV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_add}</div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                            </div>

                          </div>}
                        {(product?.other_product_data?.lens_details?.selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.LEFT_EYE || product?.other_product_data?.lens_details?.selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) &&
                          <div className={`col-12 ${product?.other_product_data?.lens_details?.selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE && 'col-md-6'}`}
                            style={{ borderLeft: `${product?.other_product_data?.lens_details?.selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE && '1px solid rgba(0, 0, 0, 0.175)'}` }}>
                            <h3 className='text-center fs-16 fw-600 dark-gray'>{`Left Eye (OS)`}</h3>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray">SPH</div>
                              <div className="col fs-12 fw-500 dark-gray">CYL</div>
                              <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                              <div className="col fs-12 fw-500 dark-gray">PD</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">DV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">NV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_add}</div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                            </div>
                          </div>}
                      </div>
                    </div>
                  </div>}
                </div>
                <div className='d-none d-md-block text-sm-start text-md-start text-lg-end mt-3 mt-md-0'>
                  {(discountAmount > 0 || discountPercentage > 0) && <p className='mb-0 fs-md-16 fs-12 fw-500 dark-gray text-decoration-line-through'>₹{numberWithCommas(parseFloat(product?.product?.product_srp?.toFixed(2)))}</p>}
                  <p className='mb-0 fs-md-18 fs-12 fw-600 dark-gray'>₹{disAmount > 0 ? numberWithCommas(parseFloat((product?.product?.product_srp - disAmount)?.toFixed(2))) : numberWithCommas(parseFloat(product?.product?.product_srp?.toFixed(2)))}</p>
                </div>
              </div>
            </div>
          </div>

          <hr className="mt-3" style={{ border: '1px solid #22222260', }} />

          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className='d-flex flex-wrap gap-md-3 gap-1 mt-2 align-items-center'>
              <h2 className="mb-0 fs-md-18 fs-12 fw-600">Item Discount :-</h2>
              <div className='d-flex mt-md-0 mt-1 gap-md-3 gap-1 align-items-center'>
                <div className='d-flex gap-1'>
                  {/* this is area  */}
                  <span>₹</span>
                  <Input type="number" placeholder='Rupee' className='roundOff-inp fs-12'
                    // disabled={(order_status == constant.ORDER_ITEM_STATUS.DELIVERED)}
                    disabled={(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY)}
                    value={discountAmount}
                    onChange={(e: any) => calculateDiscountPercentage(parseFloat(e.target.value), product.product.product_srp)} />
                </div>
                <FaArrowRightArrowLeft />
                <div>
                  <div className="d-flex gap-1">
                    <Input type="number" placeholder='Percentage%' className='roundOff-inp fs-12'
                      // disabled={(order_status == constant.ORDER_ITEM_STATUS.DELIVERED)}
                      disabled={(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY)}
                      value={discountPercentage}
                      onChange={(e: any) => e.target.value <= 100 && calculateDiscountAmount(parseFloat(e.target.value), product.product.product_srp)} />
                    <span>%</span>
                  </div>
                  {discountPercentage > product?.product?.brand_allocation_details?.brand_discount && <p className='text-danger fs-14 fw-500 mb-0'> Max discount is {product?.product?.brand_allocation_details?.brand_discount}% </p>}
                </div>
              </div>
            </div>
            {(
              // (order_status == constant.ORDER_ITEM_STATUS.DELIVERED) ||

              product.availableAtStore) &&
              product.isDelivered &&
              // product.availableAtStore && 
              <div className='d-flex flex-wrap gap-3 align-items-center mt-2'>
                <h2 className="mb-md-0 fs-md-18 fs-12 fw-600">Barcode :-</h2>
                <Select className='sales-custom-select'
                  value={{
                    key: product?.inventory_item?.id || product?.inventory_item?.key,
                    value: product?.inventory_item?.bar_code || product?.inventory_item?.value,
                    label: product?.inventory_item?.bar_code || product?.inventory_item?.label
                  }}
                  options={getDropdown(product?.product?.barcodes, 'bar_code')}
                  onChange={(e: any) => _handleBarcode(e)}
                />
              </div>
            }
            {product.status == constant.ORDER_ITEM_STATUS.DELIVERED &&
              product.isDelivered && <div className='d-flex flex-wrap gap-3 align-items-center mt-2'>
                <h2 className="mb-0 fs-18 fw-600">Barcode :-</h2>
                <div className='sales-custom-select ps-2 d-flex align-items-center'
                  style={{
                    border: '1px solid #d5d5d5',
                    borderRadius: '5px',
                    minHeight: '55px',
                    cursor: 'default'
                  }}>{product?.inventory_item?.bar_code || product?.inventory_item?.label}</div>
              </div>}
          </div>
        </div >
        :
        // ================== For Additional Products================
        <div className='checkout-product-card mt-4'>
          <div className='d-flex flex-wrap align-items-center justify-content-between gap-md-4 gap-2 '>
            <div className='d-md-none d-flex justify-content-between w-100 align-items-center'>
              <div>
                <h2 className='mb-0 fs-16 fw-600'>{product?.other_product_data?.product_type_name}</h2>
              </div>
              <div className='d-flex gap-2 text-sm-start text-md-start text-lg-end'>
                {(discountAmount > 0 || (discountPercentage || 0) > 0) && <p className='mb-0 fs-md-16 fs-12 fw-500 dark-gray text-decoration-line-through'>₹{(product?.other_product_data?.price)?.toFixed(2)}</p>}
                <p className='mb-0 fs-md-18 fs-14 fw-600 dark-gray'>₹{disAmount > 0 ? numberWithCommas(parseFloat((product?.other_product_data?.price - disAmount)?.toFixed(2))) : numberWithCommas(parseFloat((product?.other_product_data?.price)?.toFixed(2)))}</p>
              </div>
            </div>
            {product.availableAtStore &&
              // (product.status !== constant.ORDER_ITEM_STATUS.DELIVERED) &&
              ((order_status == constant.ORDER_ITEM_STATUS.DELIVERED) ||
                product.availableAtStore) &&
              <div className='d-flex flex-wrap gap-3 align-items-center'>
                {!(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY) && <div className='d-flex flex-wrap align-items-center gap-3'>
                  <Checkbox className='checkbox-style cursor-pointer'
                    onChange={(e) => _handleIsDelivered(e)}
                    id={'idDelivered'} label={'Product Is Delivered'}
                    checked={product?.isDelivered}
                    labelClass='fs-md-16 fs-12 ms-2'
                    name={'idDelivered'} />
                  {/* <h2 className='mb-0 fs-14 fw-600' >Product Is Delivered</h2> */}
                </div>}
              </div>}

            {!(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY) &&
              // ((order_status == constant.ORDER_ITEM_STATUS.DELIVERED) ||
              //   product.availableAtStore) &&
              //   product.status !== constant.ORDER_ITEM_STATUS.DELIVERED &&
              <div className='d-none d-md-flex flex-wrap align-items-center gap-md-3 gap-2'>
                <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => _handleEditProduct(product)}>Edit</h2>
                <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => _handleRemove(product?.id)}>Remove</h2>
                <h2 className='mb-0 fs-md-14 fs-12 fw-500 text-decoration-underline cursor-pointer' onClick={() => repeatItem(product?.id)}>Repeat</h2>
              </div>}
          </div>
          <div className="row ">
            {product.status === constant.ORDER_ITEM_STATUS.DELIVERED && <div className="sales-card-badge">
              <AvailableBadge title={'Delivered'} />
            </div>}
            {product.status === constant.ORDER_ITEM_STATUS.SALES_RETURN && <div className="sales-card-badge">
              <UnavailableBadge title={'RETURNEDeturned'} />
            </div>}
            <div className='row'>
              {/* <div className="col-5 col-md-3 mt-1">
                <CustomImages src={BrandPlaceHolder} imageHeight='110px' className='img-fluid product-image ' />
              </div> */}
              <div className="col-5 col-md-3 mt-3 pe-0 pe-md-1 mt-md-4" style={{ overflow: "hidden" }}>
                <div className='sales-product-image' style={{ overflow: "hidden" }}>
                  <div className=''>
                    <Image src={BrandPlaceHolder} alt="Product Image" className='img-fluid product-image ' />
                  </div>
                  <div className='d-md-none row'>
                    <div className='col-4 d-flex justify-content-center border p-1' onClick={() => _handleRemove(product?.id)}>
                      <RiDeleteBin6Line className='fs-20' style={{ color: "red" }} />
                    </div>
                    <div className='col-4 d-flex justify-content-center border p-1' onClick={() => _handleEditProduct(product)}>
                      <FiEdit className='fs-20' />
                    </div>
                    <div className='col-4 d-flex justify-content-center border p-1' onClick={() => repeatItem(product?.id)}>
                      <MdOutlineAddToPhotos className='fs-20' />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-7 col-md-9 mt-md-4 mt-3">
                <div className='row flex-wrap justify-content-between align-items-end '>
                  <div className={`col-lg-10 ${window.innerWidth < 768 ? "w-100" : "w-80"}`}>
                    <h2 className='d-none d-md-flex mb-0 fs-16 fw-600 dark-gray'>{product?.other_product_data?.product_type_name}</h2>
                    <div className="d-lg-flex gap-3 align-items-center mt-md-2" style={{ wordWrap: "break-word", overflow: "hidden" }}>
                      <h2 className='mb-0 fs-md-14 fs-12 fw-500 dark-gray'><span className=''>{product?.other_product_data?.description}</span></h2>
                    </div>
                    {product?.other_product_data?.lens_details && <div className='mt-2'>
                      <h3 className='fs-md-14 fs-11 fw-500 dark-gray'>Name : {product?.other_product_data?.lens_details?.name}</h3>
                      <h3 className='fs-md-14 fs-11 fw-500 dark-gray'>Phone : {product?.other_product_data?.lens_details?.phone} </h3>
                      <div className="d-none d-md-block card p-3 mb-2">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <h3 className='text-center fs-16 fw-600 dark-gray'>{`Right Eye (OD)`}</h3>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray">SPH</div>
                              <div className="col fs-12 fw-500 dark-gray">CYL</div>
                              <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                              <div className="col fs-12 fw-500 dark-gray">PD</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">DV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">NV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_add}</div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                            </div>

                          </div>
                          <div className="col-12 col-md-6" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.175)' }}>
                            <h3 className='text-center fs-16 fw-600 dark-gray'>{`Left Eye (OS)`}</h3>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray">SPH</div>
                              <div className="col fs-12 fw-500 dark-gray">CYL</div>
                              <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                              <div className="col fs-12 fw-500 dark-gray">PD</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">DV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">NV:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_sph}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_cyl}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_axis}</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_pd}</div>
                            </div>
                            <div className="row">
                              <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                              <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_add}</div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                              <div className="col fs-12 fw-500 dark-gray"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}
                  </div>
                  <div className='col-lg-2 d-none d-flex d-md-flex text-sm-start text-md-start text-lg-end mt-3 mt-md-0'>
                    <div>
                      {(discountAmount > 0 || discountPercentage > 0) && <p className='mb-0 fs-md-16 fs-12 fw-500 dark-gray text-decoration-line-through'>₹{(product?.other_product_data?.price)?.toFixed(2)}</p>}
                      <p className='mb-0 fs-md-18 fs-14 fw-600 dark-gray'>₹{disAmount > 0 ? (product?.other_product_data?.price - disAmount)?.toFixed(2) : (product?.other_product_data?.price)?.toFixed(2)}</p>
                    </div>
                  </div>
                </div>
                {product?.other_product_data?.lens_details && <div className='d-md-none text-start'>
                  <a className='fs-10' style={{ minWidth: "40px" }} onClick={() => setShowLensDetail(!showLensDetail)}>{!showLensDetail ? "see more" : "see less"}</a>
                </div>}
              </div>

              {/* for mobile view */}
              {(product?.other_product_data?.lens_details && showLensDetail) && <div className='d-md-none mt-2'>
                <div className="card p-3 mb-2">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h3 className='text-center fs-16 fw-600 dark-gray'>{`Right Eye (OD)`}</h3>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray"></div>
                        <div className="col fs-12 fw-500 dark-gray">SPH</div>
                        <div className="col fs-12 fw-500 dark-gray">CYL</div>
                        <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                        <div className="col fs-12 fw-500 dark-gray">PD</div>
                      </div>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray">DV:</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_sph}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_cyl}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_axis}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_dv_pd}</div>
                      </div>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray">NV:</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_sph}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_cyl}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_axis}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_nv_pd}</div>
                      </div>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.r_add}</div>
                        <div className="col fs-12 fw-500 dark-gray"></div>
                        <div className="col fs-12 fw-500 dark-gray"></div>
                        <div className="col fs-12 fw-500 dark-gray"></div>
                      </div>

                    </div>
                    <div className="col-12 col-md-6" style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.175)' }}>
                      <h3 className='text-center fs-16 fw-600 dark-gray'>{`Left Eye (OS)`}</h3>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray"></div>
                        <div className="col fs-12 fw-500 dark-gray">SPH</div>
                        <div className="col fs-12 fw-500 dark-gray">CYL</div>
                        <div className="col fs-12 fw-500 dark-gray">AXIS</div>
                        <div className="col fs-12 fw-500 dark-gray">PD</div>
                      </div>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray">DV:</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_sph}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_cyl}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_axis}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_dv_pd}</div>
                      </div>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray">NV:</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_sph}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_cyl}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_axis}</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_nv_pd}</div>
                      </div>
                      <div className="row">
                        <div className="col fs-12 fw-500 dark-gray">ADD:</div>
                        <div className="col fs-12 fw-500 dark-gray">{product?.other_product_data?.lens_details?.l_add}</div>
                        <div className="col fs-12 fw-500 dark-gray"></div>
                        <div className="col fs-12 fw-500 dark-gray"></div>
                        <div className="col fs-12 fw-500 dark-gray"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </div>

          <hr className="mt-3" style={{ border: '1px solid #22222260', }} />

          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className='d-flex flex-wrap gap-md-3 gap-1 align-items-center mt-md-2'>
              <h2 className="mb-0 fs-md-18 fs-12 fw-600">Item Discount :-</h2>
              <div className='d-flex gap-md-3 gap-1 align-items-center'>
                <div className='d-flex gap-1'>
                  <span>₹</span>
                  <Input type="number" placeholder='Rupee' className='roundOff-inp'
                    // disabled={(order_status == constant.ORDER_ITEM_STATUS.DELIVERED)}
                    disabled={(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY)}
                    value={discountAmount}
                    onChange={(e: any) => calculateDiscountPercentage(parseFloat(e.target.value), product.other_product_data.price)} />
                </div>
                <FaArrowRightArrowLeft />
                <div className="d-flex gap-1">
                  <Input type="number" placeholder='Percentage%' className='roundOff-inp'
                    // disabled={(order_status == constant.ORDER_ITEM_STATUS.DELIVERED)}
                    disabled={(sales_history_screen === constant.ORDER_ITEM_SALES_HISTORY)}
                    value={discountPercentage}
                    onChange={(e: any) => e.target.value <= 100 && calculateDiscountAmount(parseFloat(e.target.value), product.other_product_data.price)} />
                  <span>%</span>
                </div>
              </div>
            </div>
          </div>
        </div >}
      {
        handleDeleteModal && <ConfirmationModal
          show={handleDeleteModal}
          handleConfirmItem={() => removeItem()}
          onHide={() => setHandleDeleteModal(!handleDeleteModal)}
          message={'remove this product'} />
      }
    </>
  )
}
const Index = ({ orderDetails, getViewCart, setSearchProduct, productList,
  setSelectedCartItems, setBillingSummary, setSearchType }: any) => {
  const inputRef = useRef<any>(null);
  const { addToast } = useToasts();
  const [isOpen, setIsOpen] = useState(false);
  const [isProduct, setIsProduct] = useState("")
  const [showModal, setShowModal] = useState<boolean>(false)

  const [productTypeList, setProductTypeList] = useState()
  const [selectedProductType, setSelectedProductType] = useState<string>('')
  const [lensDetailsError, setLensDetailsError] = useState<boolean>(false)
  const [lensPhoneNumberError, setLensPhoneNumberError] = useState<boolean>(false)
  const [showContactLensModal, setShowContactLensModal] = useState<boolean>(false)
  const [headingForAdditionalProduct, setHeadingForAdditionalProduct] = useState()
  const [selectedProductId, setSelectedProductId] = useState('')
  const [selectedEyeType, setSelectedEyeType] = useState<any>('')
  const [eyeTypeError, setEyeTypeError] = useState<boolean>(false)
  const [isAdditionalProductEdit, setIsAdditionalProductEdit] = useState<boolean>(false)
  const [eyeTypes, setEyeTypes] = useState([
    { label: 'Right Eye', value: 1 },
    { label: 'Left Eye', value: 2 },
    { label: 'Both Eyes', value: 3 }
  ])
  const [selectedHeading, setSelectedHeading ]= useState<SelectedHeadingProps[]| null>()
  const [additionalProduct, setAdditionalProduct] = useState<IAdditionalProduct>({
    product_type_id: '',
    price: '',
    description: '',
    isAdditional: true,
    name: orderDetails?.customer?.name || '',
    phone: orderDetails?.customer?.mobile || '',
    r_dv_sph: "",
    r_dv_cyl: "",
    r_dv_axis: "",
    r_dv_pd: "",
    r_add: "",
    r_nv_sph: "",
    r_nv_cyl: "",
    r_nv_axis: "",
    r_nv_pd: "",
    l_dv_sph: "",
    l_dv_cyl: "",
    l_dv_axis: "",
    l_dv_pd: "",
    l_nv_sph: "",
    l_nv_cyl: "",
    l_nv_axis: "",
    l_nv_pd: "",
    l_add: "",
    isEdit: false,
    productId: ''
  })
  const [backupEyeDetail, setBackupEyeDetail] = useState<IAdditionalProduct>({
    product_type_id: '',
    price: '',
    description: '',
    isAdditional: true,
    name: orderDetails?.customer?.name || '',
    phone: orderDetails?.customer?.mobile || '',
    r_dv_sph: "",
    r_dv_cyl: "",
    r_dv_axis: "",
    r_dv_pd: "",
    r_add: "",
    r_nv_sph: "",
    r_nv_cyl: "",
    r_nv_axis: "",
    r_nv_pd: "",
    l_dv_sph: "",
    l_dv_cyl: "",
    l_dv_axis: "",
    l_dv_pd: "",
    l_nv_sph: "",
    l_nv_cyl: "",
    l_nv_axis: "",
    l_nv_pd: "",
    l_add: "",
    isEdit: false,
    productId: ''
  })
  let order_item_id = orderDetails?.id
  const clearFields = () => {
    return setAdditionalProduct({
      product_type_id: '',
      price: '',
      description: '',
      isAdditional: false,
      name: orderDetails?.customer?.name || '',
      phone: orderDetails?.customer?.mobile || '',
      r_dv_sph: "",
      r_dv_cyl: "",
      r_dv_axis: "",
      r_dv_pd: "",
      r_add: "",
      r_nv_sph: "",
      r_nv_cyl: "",
      r_nv_axis: "",
      r_nv_pd: "",
      l_dv_sph: "",
      l_dv_cyl: "",
      l_dv_axis: "",
      l_dv_pd: "",
      l_nv_sph: "",
      l_nv_cyl: "",
      l_nv_axis: "",
      l_nv_pd: "",
      l_add: "",
    })
  }
  const addItem = async (item: { id: string }) => {
    const payload = {
      product_id: item?.id,
    }
    try {
      await service.PendingOrder.addItem(order_item_id, payload)
      getViewCart(false)
    } catch (error: any) {
      console.log('error', error)
      addToast(error.response.data.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const searchCallBack = (search: string) => {
    setIsProduct(search)
    setSearchProduct(search)
    setIsOpen(true)
  }

  const updateDiscount = (productId: number, discountPercentage: number, discountAmount: number) => {
    setSelectedCartItems((prevArray: any) =>
      prevArray?.map((obj: any) =>
        obj?.order_item_id === productId
          ? { ...obj, discount: discountAmount }
          : obj
      )
    )
  };

  const getDropdownAdditionalProductHeading = (response: []) => {
    let dropdown: any = []
    response && response.map((value: any) => {
        dropdown.push({
            "key": value.id,
            "value": value.id,
            "label": value.lens_data.heading,
            "amount":value.lens_data.amount,
            "description":value.lens_data?.description
        }
        )
    })
    return dropdown
}

  const getHeadingForAdditionalProduct = async () => {
    try {
        let response = await service.Product.lensDetails()
        if (response.status === 200) {
            setHeadingForAdditionalProduct(getDropdownAdditionalProductHeading(response.data.data))
        }
    } catch (error) {
        console.log('error', error)
    }
}

  const getProductTypeList = async () => {
    try {
      const response = await service.Product.productTypeList()
      if (response.status === 200) {
        setProductTypeList(getDropdown(response.data.data, 'name'))
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const _handleSelectedProductType = (obj: any, isEyeType: any = false) => {
    setAdditionalProduct({
      product_type_id: isEyeType ? '' : obj.value,
      price: '',
      description: '',
      isAdditional: false,
      name: orderDetails?.customer?.name || additionalProduct?.name || '',
      phone: orderDetails?.customer?.mobile || additionalProduct?.phone || '',
      r_dv_sph: "",
      r_dv_cyl: "",
      r_dv_axis: "",
      r_dv_pd: "",
      r_add: "",
      r_nv_sph: "",
      r_nv_cyl: "",
      r_nv_axis: "",
      r_nv_pd: "",
      l_dv_sph: "",
      l_dv_cyl: "",
      l_dv_axis: "",
      l_dv_pd: "",
      l_nv_sph: "",
      l_nv_cyl: "",
      l_nv_axis: "",
      l_nv_pd: "",
      l_add: "",
    });
    if (isEyeType) {
      // clearFields()
      setSelectedEyeType(obj)
    } else {
      setSelectedProductType(obj.label)
    }
  }

  // for select the heading
  const _handleSelectedHeading = (obj: any) => {
    setSelectedHeading(obj)
    setAdditionalProduct({
      ...additionalProduct,
      price: obj.amount,
      description: obj.description,
    });
  }
  // end


  const _handleSelectedEyeType = (obj: any) => {
    let tempEyeDetail = additionalProduct
    if (obj && obj.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) {
      if (
        !(tempEyeDetail.r_dv_sph === tempEyeDetail.l_dv_sph &&
          tempEyeDetail.r_dv_cyl === tempEyeDetail.l_dv_cyl &&
          tempEyeDetail.r_dv_axis === tempEyeDetail.l_dv_axis &&
          tempEyeDetail.r_dv_pd === tempEyeDetail.l_dv_pd &&
          tempEyeDetail.r_add === tempEyeDetail.l_add &&
          tempEyeDetail.r_nv_sph === tempEyeDetail.l_nv_sph &&
          tempEyeDetail.r_nv_cyl === tempEyeDetail.l_nv_cyl &&
          tempEyeDetail.r_nv_axis === tempEyeDetail.l_nv_axis &&
          tempEyeDetail.r_nv_pd === tempEyeDetail.l_nv_pd)
      ) {
        setAdditionalProduct({
          product_type_id: '',
          price: '',
          description: '',
          isAdditional: false,
          name: additionalProduct?.name || '',
          phone: additionalProduct?.phone || '',
          r_dv_sph: "",
          r_dv_cyl: "",
          r_dv_axis: "",
          r_dv_pd: "",
          r_add: "",
          r_nv_sph: "",
          r_nv_cyl: "",
          r_nv_axis: "",
          r_nv_pd: "",
          l_dv_sph: "",
          l_dv_cyl: "",
          l_dv_axis: "",
          l_dv_pd: "",
          l_nv_sph: "",
          l_nv_cyl: "",
          l_nv_axis: "",
          l_nv_pd: "",
          l_add: "",
        });
      }
    } else {
      let tempBackupDetail = { ...backupEyeDetail };
      tempBackupDetail.name = additionalProduct?.name || '';
      tempBackupDetail.phone = additionalProduct?.phone || '';
      setAdditionalProduct(tempBackupDetail);
      // setAdditionalProduct(backupEyeDetail)
    }
    setSelectedEyeType(obj)
    setLensPhoneNumberError(false)
  }

  const formatValue = (value: number, targetValue: string) => {
    return value !== undefined ? ((/\+/.test(targetValue)) ? `+${value}` : `${value}`) : '';
  }

  const handleSubmit = async () => {
    const payload: any = {
      product_type_id: additionalProduct?.product_type_id,
      price: additionalProduct?.price,
      description: additionalProduct?.description,
      isAdditional: true,
      lens_details: (isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name) || showContactLensModal) ? {
        name: additionalProduct?.name,
        phone: additionalProduct?.phone,

        r_dv_sph: additionalProduct?.r_dv_sph,
        r_dv_cyl: (additionalProduct?.r_dv_cyl),
        r_dv_axis: (additionalProduct?.r_dv_axis),
        r_dv_pd: (additionalProduct?.r_dv_pd),
        r_add: (additionalProduct?.r_add),
        r_nv_sph: (additionalProduct?.r_nv_sph),
        r_nv_cyl: (additionalProduct?.r_nv_cyl),
        r_nv_axis: (additionalProduct?.r_nv_axis),
        r_nv_pd: (additionalProduct?.r_nv_pd),

        l_dv_sph: (additionalProduct?.l_dv_sph),
        l_dv_cyl: (additionalProduct?.l_dv_cyl),
        l_dv_axis: (additionalProduct?.l_dv_axis),
        l_dv_pd: (additionalProduct?.l_dv_pd),
        l_nv_sph: (additionalProduct?.l_nv_sph),
        l_nv_cyl: (additionalProduct?.l_nv_cyl),
        l_nv_axis: (additionalProduct?.l_nv_axis),
        l_nv_pd: (additionalProduct?.l_nv_pd),
        l_add: (additionalProduct?.l_add),

      } : null
    }
    if (showContactLensModal) {
      payload.product_type_id = ''
      payload.price = ''
      payload.description = ''
      payload.isAdditional = false
      payload.orderItemId = selectedProductId
      payload.lens_details.selectedEyeType = selectedEyeType
    }
    let response
    try {
      if ((isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name))
        && (additionalProduct?.name.length <= 0 || additionalProduct?.phone == null) || showContactLensModal && (additionalProduct?.name.length <= 0 || additionalProduct?.phone == null || !selectedEyeType)) {
        setLensDetailsError(true)

      } else {
        setLensDetailsError(false)
        if (showContactLensModal) {
          response = await service.SalesCart.updatePendingLensDetail(selectedProductId, payload)
        } else {
          if (additionalProduct.isEdit) {
            response = await service.PendingOrder.EditItem(additionalProduct?.productId || '', payload)

          } else {
            response = await service.PendingOrder.addAdditionalProducts(order_item_id, payload)

          }
        }
        if (response.status === 200) {
          getViewCart(false)
          setShowModal(false)
          setShowContactLensModal(false)
          setSelectedEyeType('')
          setSelectedProductType('')
          clearFields()
        }

      }

    } catch (error: any) {
      console.log('error', error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }


  const _handleOnHide = () => {
    setShowModal(false)
    setSelectedEyeType('')
    setShowContactLensModal(false)
    setSelectedProductType('')
    clearFields()
  }

  const getLensContactLensDetail = async (phoneNumber: number, selectedProductType: string) => {
    try {
      setLensPhoneNumberError(true)
      if (phoneNumber?.toString()?.length === 10) {
        const response = await service?.Prescription?.searchPrescription({ search: phoneNumber })
        let type = showContactLensModal ? 'contact_lens' : selectedProductType.toLowerCase()?.replace(/\s/g, '_')
        if (response.status === 200) {
          let lensDetail = response?.data?.data?.[type]?.prescription_details
          if (showContactLensModal) {
            const eyeType = selectedEyeType || determineEyeType(lensDetail);
            const getEyeValues = (details: any) => ({
              r_dv_sph: details?.r_dv_sph || '',
              r_dv_cyl: details?.r_dv_cyl || '',
              r_dv_axis: details?.r_dv_axis || '',
              r_dv_pd: details?.r_dv_pd || '',
              r_add: details?.r_add || '',
              r_nv_sph: details?.r_nv_sph || '',
              r_nv_cyl: details?.r_nv_cyl || '',
              r_nv_axis: details?.r_nv_axis || '',
              r_nv_pd: details?.r_nv_pd || '',
              l_dv_sph: details?.l_dv_sph || '',
              l_dv_cyl: details?.l_dv_cyl || '',
              l_dv_axis: details?.l_dv_axis || '',
              l_dv_pd: details?.l_dv_pd || '',
              l_nv_sph: details?.l_nv_sph || '',
              l_nv_cyl: details?.l_nv_cyl || '',
              l_nv_axis: details?.l_nv_axis || '',
              l_nv_pd: details?.l_nv_pd || '',
              l_add: details?.l_add || '',
            });
            const commonValues = {
              name: lensDetail?.name || '',
            };
            setSelectedEyeType(eyeType);
            const eyeValues = selectedEyeType
              ? (selectedEyeType.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE && !calculateEyeConditions(lensDetail)?.isBothEqual
                ? getEyeValues({})
                : getEyeValues(lensDetail))
              : getEyeValues(lensDetail);
            setAdditionalProduct({
              ...additionalProduct,
              ...commonValues,
              ...eyeValues,
            });
            setBackupEyeDetail({
              ...backupEyeDetail,
              ...commonValues,
              phone: lensDetail?.phone || '',
              ...getEyeValues(lensDetail),
            });
          } else {
            setAdditionalProduct({
              ...additionalProduct,
              name: lensDetail?.name || '',
              r_dv_sph: lensDetail?.r_dv_sph || '',
              r_dv_cyl: lensDetail?.r_dv_cyl || '',
              r_dv_axis: lensDetail?.r_dv_axis || "",
              r_dv_pd: lensDetail?.r_dv_pd || '',
              r_add: lensDetail?.r_add || '',
              r_nv_sph: lensDetail?.r_nv_sph || '',
              r_nv_cyl: lensDetail?.r_nv_cyl || '',
              r_nv_axis: lensDetail?.r_nv_axis || '',
              r_nv_pd: lensDetail?.r_nv_pd || '',
              l_dv_sph: lensDetail?.l_dv_sph || '',
              l_dv_cyl: lensDetail?.l_dv_cyl || '',
              l_dv_axis: lensDetail?.l_dv_axis || '',
              l_dv_pd: lensDetail?.l_dv_pd || '',
              l_nv_sph: lensDetail?.l_nv_sph || '',
              l_nv_cyl: lensDetail?.l_nv_cyl || '',
              l_nv_axis: lensDetail?.l_nv_axis || '',
              l_nv_pd: lensDetail?.l_nv_pd || '',
              l_add: lensDetail?.l_add || '',
            })
          }
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const clearSearchField = () => {
    if (inputRef?.current) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    getProductTypeList()
    getHeadingForAdditionalProduct()
  }, [])

  const clearEyeDetail = () => {
    const clearFields = (fields: any) => {
      return fields.reduce((acc: any, field: string) => {
        acc[field] = '';
        return acc;
      }, {});
    };

    const leftEyeFields = [
      'l_dv_sph', 'l_dv_cyl', 'l_dv_axis', 'l_dv_pd',
      'l_nv_sph', 'l_nv_cyl', 'l_nv_axis', 'l_nv_pd', 'l_add'
    ];

    const rightEyeFields = [
      'r_dv_sph', 'r_dv_cyl', 'r_dv_axis', 'r_dv_pd',
      'r_nv_sph', 'r_nv_cyl', 'r_nv_axis', 'r_nv_pd', 'r_add'
    ];

    let clearedFields = {};

    if (selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.LEFT_EYE) {
      clearedFields = clearFields(leftEyeFields);
    } else if (selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.RIGHT_EYE) {
      clearedFields = clearFields(rightEyeFields);
    } else if (selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) {
      clearedFields = {
        ...clearFields(leftEyeFields),
        ...clearFields(rightEyeFields)
      };
    }

    setAdditionalProduct({
      ...additionalProduct,
      ...clearedFields
    });

    setBackupEyeDetail({
      ...additionalProduct,
      ...clearedFields
    });
  };
  return (
    <div className='sales-checkout-card'>
      <div className="d-md-flex">
        <h2 className='mb-0 fs-16 fw-600 mb-md-0 mb-3'>Cart ({orderDetails?.order_items?.length} items)</h2>
        {!(orderDetails?.order_status === constant?.ORDER_ITEM_SALES_HISTORY) && <div className='d-flex mt-md-0 mt-1'>
          <RadioInput type="radio" name='search_type'
            className="ms-md-2"
            labelClass='fs-md-16 fs-14'
            onChange={(e: any) => { setSearchType('barcode'); clearSearchField(); searchCallBack("") }}
            checked={orderDetails?.searchType === 'barcode'}
            value={'barcode'} label='Barcode' id={'Yes'}
          />
          <RadioInput type="radio" name='search_type'
            className="ms-2"
            labelClass='fs-md-16 fs-14'
            onChange={(e: any) => { setSearchType('search'); clearSearchField(); searchCallBack("") }}
            checked={orderDetails?.searchType === 'search'}
            value={'search'} label={`Product Id / Modal ${window.innerWidth < 768 ? 'No.' : 'Number'}`} id={'No'}
          />
        </div>}
      </div>
      {(orderDetails?.order_status !== constant.ORDER_STATUS.DELIVERED) &&
        <>
          <div className='mt-sm-4 mt-2 position-relative' onClick={() => setIsOpen(!isOpen)}>
            <Search placeholder={orderDetails?.searchType === 'search' ? 'Enter Product ID Or Modal Number' : 'Enter Barcode'}
              searchCallBack={searchCallBack} inputRef={inputRef}
              className='fs-16 new-custom-search sales-input-height' heading={'Search Product'} />
            {(isProduct.length > 0 && isOpen) && <SearchTab data={productList} searchCallBack={addItem} />}
          </div>

          <div className="col-12 mt-sm-4 mt-2 other-items-btn"
            onClick={() => setShowModal(true)}>
            Add Additional Products
            <IoMdAddCircleOutline className='fs-22 ms-1' />
          </div>
        </>}

      {orderDetails && orderDetails?.order_items?.map((item: any, index: number) => {
        return (
          <CheckoutCard key={item.id} order_item_id={order_item_id} product={item}
            onUpdateDiscount={updateDiscount} getViewCart={getViewCart}
            setSelectedCartItems={setSelectedCartItems} setShowModal={setShowModal}
            setAdditionalProduct={setAdditionalProduct}
            setBackupEyeDetail={setBackupEyeDetail}
            order_status={item?.status}
            sales_history_screen={orderDetails?.order_status}
            setBillingSummary={setBillingSummary}
            setShowContactLensModal={setShowContactLensModal}
            setSelectedProductId={setSelectedProductId}
            setSelectedEyeType={setSelectedEyeType}
            orderDetails={orderDetails}
            setIsAdditionalProductEdit={setIsAdditionalProductEdit}
          // customerInfo={customerInfo}
          />
        )
      })}

      {showModal && <CreateModal
        show={showModal}
        onHide={() => _handleOnHide()}
        submitBtnText={`${additionalProduct.isEdit && !showContactLensModal ? 'Edit Product'
          : !additionalProduct.isEdit && !showContactLensModal ? 'Add Product'
            : showContactLensModal ? 'Add Prescription'
              : ''}`}
        handleSubmit={handleSubmit}
        maxWidth={(showContactLensModal || isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name)) ? '1000px' : ''}
      >
        <div>
          {!showContactLensModal && <div className='row'>
            <div className="col-6">
              {!additionalProduct?.isEdit && <div className="form-outline mb-4"
              //  style={{ minWidth: '225px' }}
              >
                <Select
                  options={productTypeList}
                  onChange={(obj: any) => _handleSelectedProductType(obj)}
                  label={"Product Type"} placeholder={'Select'} />
              </div>}
              {additionalProduct?.isEdit &&
                <div className="mb-4 d-flex flex-wrap align-items-center">
                  <label className='inputs-label mb-2 fs-16 fw-600  secondary-color'>{'Product Type'}</label>
                  <div className='w-100 ps-2 d-flex align-items-center'
                    style={{
                      border: '1px solid #d5d5d5',
                      backgroundColor: '#f3f3f3',
                      borderRadius: '5px',
                      minHeight: '55px',
                      cursor: 'default'
                    }}>{additionalProduct?.product_type_name}</div>
                </div>
              }
            </div>
            <div className='col-6'>
              <Input type={"number"}
                onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, price: e.target.value })}
                label={"Amount"} value={additionalProduct?.price} placeholder={'Enter Amount'} />
            </div>
          </div>}
          {!showContactLensModal &&
            <div className='row'>
              <div className={`form-outline mb-4 ${!isAdditionalProductEdit ? "col-6" : "col-12"}`}
              >
                <TextArea type={"text"}
                  onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, description: e.target.value })}
                  label={"Description"} value={additionalProduct?.description} placeholder={'Enter Description'} />
              </div>

              {!isAdditionalProductEdit && <div className="form-outline mb-4 col-6"
              >
                <Select
                  options={headingForAdditionalProduct}
                  onChange={(obj: any) => _handleSelectedHeading(obj)}
                  label={"Heading"} placeholder={'Select'}
                  value={selectedHeading} />
              </div>}
            </div>

          }
          {(showContactLensModal || isValidProductType(selectedProductType) || isValidProductType(additionalProduct?.product_type_name)) &&
            <>
              <div className='row'>
                <div className="col-12 col-md-6">
                  <div className="form-outline mb-4" style={{ minWidth: '225px' }}>
                    <Input type={"text"}
                      onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, name: e.target.value })}
                      label={"Name"} value={additionalProduct?.name} placeholder={'Enter Name'}
                      errors={!additionalProduct?.name && lensDetailsError && 'This field is required'} />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-outline mb-4">
                    <label className='inputs-label mb-2 fs-16 fw-600 secondary-color'>Phone Number</label>
                    <div style={{ position: "relative" }}>
                      <Input type={"number"}
                        onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, phone: e.target.value })}
                        value={additionalProduct?.phone} placeholder={'Enter Phone Number'}
                        errors={!additionalProduct?.phone && lensDetailsError && 'This field is required'}
                      />
                      {lensPhoneNumberError && additionalProduct?.phone && !(additionalProduct?.phone?.toString()?.length === 10) && <span className="error-text">phone number should be 10 digit</span>}
                      <Button className={`position-absolute shadow-none border-0 add-product-btn`}
                        isDisabled={!additionalProduct?.phone}
                        onClick={() => getLensContactLensDetail(additionalProduct?.phone, selectedProductType)} >
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-md-6">
                  <div className="form-outline mb-4">
                    <Input type={"number"}
                      onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, phone: e.target.value })}
                      label={"Phone Number"} value={additionalProduct?.phone} placeholder={'Enter Phone Number'}
                      errors={!additionalProduct?.phone && lensDetailsError && 'This field is required'} />
                  </div>
                </div> */}
              </div>
              {showContactLensModal && <div className='row'>
                <div className="col-6">

                  <div className="form-outline mb-4"
                  // style={{ minWidth: '225px' }}
                  >
                    <Select
                      options={eyeTypes}
                      value={selectedEyeType}
                      onChange={(obj: any) => _handleSelectedEyeType(obj)}
                      label={"Select Eye"} placeholder={'Select'}
                      errors={((!selectedEyeType && eyeTypeError) || (!selectedEyeType && lensDetailsError)) && 'This field is required'}
                    />
                  </div>

                </div>
              </div>}
              {(showContactLensModal && selectedEyeType || !showContactLensModal) &&
                <div className='row'>
                  <div className="col-12 ">
                    <div className="card">
                      <div className={`row px-2 my-2 ${(showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.RIGHT_EYE || showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.LEFT_EYE || showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) && 'd-flex justify-content-center'}`}>
                        {((showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.RIGHT_EYE) || (showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) || !showContactLensModal) && <div className="col-12 col-md-6">
                          <h3 className='text-center inputs-label m-2 fs-16 fw-600 secondary-color'>{`${showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE ? 'BOTH EYE (OD)' : 'RIGHT EYE (OD)'}`}</h3>

                          <div className="d-flex gap-1">
                            <div className="align-items-center d-flex" style={{ width: '225px' }} >
                              <h4 className='fs-16 fw-600 secondary-color'>{`DV :`}</h4>
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => {
                                  const newValue = e.target.value;
                                  const updatedProduct = { ...additionalProduct };

                                  if (showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) {
                                    updatedProduct.l_dv_sph = newValue;
                                    updatedProduct.l_nv_sph = formatValue(
                                      sumTwoNumbers(parseFloat(newValue), parseFloat(additionalProduct.l_add)),
                                      newValue
                                    );
                                  }

                                  updatedProduct.r_dv_sph = newValue;
                                  updatedProduct.r_nv_sph = formatValue(
                                    sumTwoNumbers(parseFloat(newValue), parseFloat(additionalProduct.r_add)),
                                    newValue
                                  );

                                  setAdditionalProduct(updatedProduct);
                                }}

                                label='SPH' value={additionalProduct?.r_dv_sph} placeholder='' />
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => {
                                  const newValue = e.target.value;
                                  const updatedProduct = { ...additionalProduct };

                                  if (showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) {
                                    updatedProduct.l_dv_cyl = newValue;
                                    updatedProduct.l_nv_cyl = newValue;
                                  }

                                  updatedProduct.r_dv_cyl = newValue;
                                  updatedProduct.r_nv_cyl = newValue;

                                  setAdditionalProduct(updatedProduct);
                                }}

                                label='CYL' value={additionalProduct?.r_dv_cyl} placeholder='' />
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => {
                                  const newValue = e.target.value;
                                  const updatedProduct = { ...additionalProduct };

                                  if (showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) {
                                    updatedProduct.l_dv_axis = newValue;
                                    updatedProduct.l_nv_axis = newValue;
                                  }
                                  updatedProduct.r_dv_axis = newValue;
                                  updatedProduct.r_nv_axis = newValue;


                                  setAdditionalProduct(updatedProduct);
                                }}

                                label='AXIS' value={additionalProduct?.r_dv_axis} placeholder='' />
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => {
                                  const newValue = e.target.value;
                                  const updatedProduct = { ...additionalProduct };

                                  if (showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) {
                                    updatedProduct.l_dv_pd = newValue;
                                    updatedProduct.l_nv_pd = newValue;
                                  }

                                  updatedProduct.r_dv_pd = newValue;
                                  updatedProduct.r_nv_pd = newValue;

                                  setAdditionalProduct(updatedProduct);
                                }}

                                label='PD' value={additionalProduct?.r_dv_pd} placeholder='' />
                            </div>
                          </div>
                          <div className="d-flex mt-2 gap-1">
                            <div className="align-items-center d-flex" style={{ width: '225px' }}>
                              <h4 className='fs-16 fw-600 secondary-color'>{`NV :`}</h4>
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => ''}
                                value={additionalProduct.r_nv_sph}
                                disabled={true} placeholder='' />
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => ''} disabled={true}
                                value={additionalProduct?.r_nv_cyl} placeholder='' />
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => ''} disabled={true}
                                value={additionalProduct?.r_nv_axis} placeholder='' />
                            </div>
                            <div className="">
                              <Input type='text'
                                onChange={(e: any) => ''} disabled={true}
                                value={additionalProduct?.r_nv_pd} placeholder='' />
                            </div>
                          </div>
                          <div className="d-flex mt-2">
                            <div className=" align-items-center d-flex" style={{ width: '100px' }}>
                              <h4 className='fs-16 fw-600 secondary-color'>{`ADD :`}</h4>
                            </div>
                            <div className="" style={{ width: '110px' }}>
                              <Input type='text'
                                onChange={(e: any) => {
                                  const newValue = e.target.value;
                                  const updatedProduct = { ...additionalProduct };

                                  if (showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.BOTH_EYE) {
                                    updatedProduct.l_add = newValue;
                                    updatedProduct.l_nv_sph = formatValue(
                                      sumTwoNumbers(parseFloat(additionalProduct.l_dv_sph), parseFloat(newValue)),
                                      newValue
                                    );
                                  }

                                  updatedProduct.r_add = newValue;
                                  updatedProduct.r_nv_sph = formatValue(
                                    sumTwoNumbers(parseFloat(additionalProduct.r_dv_sph), parseFloat(newValue)),
                                    newValue
                                  );

                                  setAdditionalProduct(updatedProduct);
                                }}

                                value={additionalProduct?.r_add} placeholder='' />
                            </div>
                          </div>
                        </div>}
                        {((showContactLensModal && selectedEyeType?.value === constant.EYE_TYPE_CONTACT_LENS.LEFT_EYE) || !showContactLensModal) &&
                          <div className={`col-12 col-md-6 mt-4 mt-md-0 ${!showContactLensModal && 'left-border'} `}
                          //  style={{ borderLeft: '1px solid rgba(0, 0, 0, 0.175)' }}
                          >
                            {!showContactLensModal && <hr className='d-block d-md-none' />}

                            <h3 className='text-center inputs-label my-2 fs-16 fw-600 secondary-color'>{`LEFT EYE (OS)`}</h3>
                            <div className="d-flex gap-1">
                              <div className="align-items-center d-flex" style={{ width: '225px' }} >
                                <h4 className='fs-16 fw-600 secondary-color'>{`DV :`}</h4>
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_sph: e.target.value, l_nv_sph: formatValue(sumTwoNumbers(e.target.value, parseFloat(additionalProduct.l_add)), e.target.value) })}
                                  label='SPH' value={additionalProduct?.l_dv_sph} placeholder='' />
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_cyl: e.target.value, l_nv_cyl: e.target.value })}
                                  label='CYL' value={additionalProduct?.l_dv_cyl} placeholder='' />
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_axis: e.target.value, l_nv_axis: e.target.value })}
                                  label='AXIS' value={additionalProduct?.l_dv_axis} placeholder='' />
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_dv_pd: e.target.value, l_nv_pd: e.target.value })}
                                  label='PD' value={additionalProduct?.l_dv_pd} placeholder='' />
                              </div>
                            </div>
                            <div className="d-flex mt-2 gap-1">
                              <div className=" align-items-center d-flex" style={{ width: '225px' }} >
                                <h4 className='fs-16 fw-600 secondary-color'>{`NV :`}</h4>
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => ''}
                                  value={additionalProduct.l_nv_sph}
                                  disabled={true} placeholder='' />
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => ''} disabled={true}
                                  value={additionalProduct?.l_nv_cyl} placeholder='' />
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => ''} disabled={true}
                                  value={additionalProduct?.l_nv_axis} placeholder='' />
                              </div>
                              <div className="">
                                <Input type='text'
                                  onChange={(e: any) => ''} disabled={true}
                                  value={additionalProduct?.l_nv_pd} placeholder='' />
                              </div>
                            </div>
                            <div className="d-flex mt-2">
                              <div className="align-items-center d-flex" style={{ width: '100px' }} >
                                <h4 className='fs-16 fw-600 secondary-color'>{`ADD :`}</h4>
                              </div>
                              <div className="" style={{ width: '110px' }}>
                                <Input type='text'
                                  onChange={(e: any) => setAdditionalProduct({ ...additionalProduct, l_add: e.target.value, l_nv_sph: formatValue(sumTwoNumbers(parseFloat(additionalProduct.l_dv_sph), e.target.value), e.target.value) })}
                                  value={additionalProduct?.l_add} placeholder='' />
                              </div>
                            </div>
                          </div>}
                        <div className="d-flex justify-content-end">

                          <div className="" >
                            <Button
                              isDisabled={!selectedEyeType}
                              className={`shadow-none border-0 add-product-btn`}
                              onClick={() =>
                                clearEyeDetail()
                              }
                            >Clear All</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
            </>

          }
        </div>
      </CreateModal>}



    </div>
  )
}

export default Index