import React from 'react'
import atoms from '../../../atoms'
import moment from 'moment'
import { convertToCommaFormat } from '../../../../constants/utils'
import { AiOutlineEye } from 'react-icons/ai'
import { ECancelledData, ECancelledProps } from './interface'
import { useNavigate } from 'react-router'
const { Search, Table, Button, Pagination, Image } = atoms

const Index = ({ receiptData, setSearch, setLimitPerPage, setCurrentPage, currentPage, limit, handleView, handleCancel }: ECancelledProps) => {
const navigate = useNavigate()
    const columns = [
        { name: 'Date', selector: (row: ECancelledData) => moment(row?.createdAt).format('DD/MM/YYYY'), width: '100px' },
        { name: 'Online  Order No.', selector: (row: ECancelledData) => row?.order_id_display, width: '150px' },
        { name: 'Customer Name', selector: (row: ECancelledData) => row?.customer?.name || '-', width: '150px' },
        { name: 'Mobile No.', selector: (row: ECancelledData) => row?.customer?.mobile, width: '150px' },
        { name: 'Total Amount', selector: (row: ECancelledData) => `₹${convertToCommaFormat(row?.total_amount)}` || '-', width: '150px' },
        {
            name: 'Total Items', selector: (row: ECancelledData) => <>
                <div>
                    <p className='mb-1 ' >Total Order : {row.total_order_items}</p>
                    <p className='mb-1 '>Delivered : {row.total_delivered_order_items}</p>
                    <p className='mb-1 '>Pending : {row.total_pending_order_items}</p>

                </div>
            </>, width: '140px'
        },
        { name: 'Delivery', selector: (row: any) => row?.order_status || '-', width: '150px', text: "center" },
        { name: 'Action', selector: (row: any) => row?.action, center: true },
    ];

    return (
        <>
            <div className='row mt-md-0 mt-3'>
                <div className='col-md-6'>
                    <Search className='search search-style w-100' searchCallBack={(e: string) => { setSearch(e) }} />
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={receiptData?.data?.map((item: any) => {
                            return ({
                                ...item,
                                order_status: item?.store_pick_up ? <div className="background-light-yellow border-product-radius custom-badge">
                                    <p className="color-yellow m-1 p-1">{'Store pickup'}</p>
                                </div> : <div className="background-light-green border-product-radius custom-badge">
                                    <p className="color-green m-1 p-1">{'Home pickup'}</p>
                                </div>,
                                action: <>
                                    <Button className='order'
                                        onClick={() => { navigate(`/online/sales-cart/${item.id}?e-cancelled=${true}`) }}>
                                        <AiOutlineEye className='fs-16' />
                                        <span className='ms-2 mb-0'>View</span>
                                    </Button>
                                </>
                            })
                        })}
                    />

                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={receiptData?.totalPages}
                        onChangePage={(page: number) => setCurrentPage(page)}
                        setLimit={(e: number) => setLimitPerPage(e)}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    )
}

export default Index