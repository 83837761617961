import React from "react";
import atoms from "../../../atoms";
import moment from "moment";
import { convertToCommaFormat } from "../../../../constants/utils";

import { ESalesHistoryData, ESalesHistoryProps } from "./interface";
import { MdOutlineFileDownload } from "react-icons/md";
import ThreeDots from "../../../../assets/Images/threeDots.svg";
import { FaPrint } from "react-icons/fa6";
import { FiShare } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router";

const { Search, Table, Pagination, Image } = atoms;

const Index = ({
    receiptData,
    setSearch,
    setLimitPerPage,
    setCurrentPage,
    currentPage,
    limit,
    handleEPrint,
    handlePrintSharePdf,
}: ESalesHistoryProps) => {
    const navigate = useNavigate();

    const columns = [
        {
            name: "Date",
            selector: (row: ESalesHistoryData) =>
                moment(row?.createdAt).format("DD/MM/YYYY"),
            width: "100px",
        },
        {
            name: "Online  Order No.",
            selector: (row: ESalesHistoryData) => row?.order_id_display,
            width: "150px",
        },
        {
            name: "Customer Name",
            selector: (row: ESalesHistoryData) => row?.customer?.name || "-",
            width: "150px",
        },
        {
            name: "Mobile No.",
            selector: (row: ESalesHistoryData) => row?.customer?.mobile,
            width: "150px",
        },
        {
            name: "Total Amount",
            selector: (row: ESalesHistoryData) =>
                `₹${convertToCommaFormat(row?.total_amount)}` || "-",
            width: "150px",
        },
        {
            name: "Total Items",
            selector: (row: ESalesHistoryData) => (
                <>
                    <div>
                        <p className="mb-1 ">Total Order : {row.total_order_items}</p>
                        <p className="mb-1 ">
                            Delivered : {row.total_delivered_order_items}
                        </p>
                        <p className="mb-1 ">Pending : {row.total_pending_order_items}</p>
                    </div>
                </>
            ),
            width: "140px",
        },
        {
            name: "Delivery",
            selector: (row: any) => row?.order_status || "-",
            width: "150px",
            text: "center",
        },
        { name: "Action", selector: (row: any) => row?.action, center: true },
    ];

    return (
        <>
            <div className="row mt-md-0 mt-3">
                <div className="col-md-6">
                    <Search
                        className="search search-style w-100"
                        searchCallBack={(e: string) => {
                            setSearch(e);
                            setCurrentPage(1);
                        }}
                    />
                </div>
                <div>
                    <Table
                        columns={columns}
                        data={receiptData?.data?.map((item: any) => {
                            return {
                                ...item,
                                order_status: item?.store_pick_up ? (
                                    <div className="background-light-yellow border-product-radius custom-badge">
                                        <p className="color-yellow m-1 p-1">{"Store pickup"}</p>
                                    </div>
                                ) : (
                                    <div className="background-light-green border-product-radius custom-badge">
                                        <p className="color-green m-1 p-1">{"Home pickup"}</p>
                                    </div>
                                ),
                                action: (
                                    <>
                                        {
                                            <div className="table-data-width">
                                                <button
                                                    className="btn dropdown-dots shadow-none action-button"
                                                    type="button"
                                                    id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <Image src={ThreeDots} />
                                                </button>
                                                <ul
                                                    className="dropdown-menu dropdown-menu-end dropdown-style zindex-tooltip"
                                                    aria-labelledby="dropdownMenuButton1"
                                                    style={{ width: "210px" }}
                                                >
                                                    {/* <hr className="my-1"></hr> */}
                                                    {/* Download Option */}

                                                    <li>
                                                        <button
                                                            className="dropdown-item dropdown-item-size"
                                                            onClick={() =>
                                                                navigate(
                                                                    `/online/sales-cart/${item.id
                                                                    }?e-delivered=${true}`
                                                                )
                                                            }
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <AiOutlineEye />
                                                                <span className="ms-2 mb-0">view</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                    <hr className="my-1"></hr>
                                                    {/* Download Option */}

                                                    <li>
                                                        <button
                                                            className="dropdown-item dropdown-item-size"
                                                            onClick={() => handleEPrint(item?.id)}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <FaPrint />
                                                                <span className="ms-2 mb-0">Print</span>
                                                            </div>
                                                        </button>
                                                    </li>

                                                    <hr className="my-1"></hr>

                                                    {/* Share Option */}
                                                    <li>
                                                        <button
                                                            className="dropdown-item dropdown-item-size"
                                                            onClick={() =>
                                                                handlePrintSharePdf(item?.id, true)
                                                            }
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <FiShare />
                                                                <span className="ms-2 mb-0">Share</span>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </>
                                ),
                            };
                        })}
                    />
                </div>
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={receiptData?.totalPages}
                        onChangePage={(page: number) => setCurrentPage(page)}
                        setLimit={(e: number) => {
                            setLimitPerPage(e);
                            setCurrentPage(1);
                        }}
                        limit={limit}
                    />
                </div>
            </div>
        </>
    );
};

export default Index;
